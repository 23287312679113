import { Avatar as MuiAvatar, Badge, Box, Paper, Typography, Zoom } from '@mui/material';
import { Add, CameraAltRounded, CreateOutlined, InstallMobile, ManageAccounts, Share } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ImageInput } from '../components/fileInput';
import { Album, createAlbumImage, Media } from '../entities/album';
import { RootState } from '../entities/store';
import { Avatar } from '../components/avatar';
import { theme } from '../theme';
import { AnimatePresence, motion } from 'framer-motion';
import { LikeProvider } from '../likes/likeContext';
import { ThemedSwipeCard } from '../swipe/swipe';
import { CreateReferralLinkDialog } from './components/createReferralLink';
import { installAppEvent, installAppPrompt } from '../serviceWorkerRegistration';
import { useTranslation } from 'react-i18next';

const style = {
    icon: {
        height: '5vh',
        width: '5vh'
    },
    iconAddImage: {
        color: theme.palette.common.white,
        height: '6vh',
        width: '6vh'
    },
    iconBox: {
        alignItems: 'center',
        backgroundColor: theme.palette.grey['200'],
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        height: '10vh',
        width: '10vh'
    },
    iconBoxAddImage: {
        alignItems: 'center',
        background: `linear-gradient(0.125turn, ${theme.palette.secondary.dark}, ${theme.palette.secondary.main})`,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        height: '10vh',
        width: '10vh'
    },
    iconContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    iconLabel: {
        color: theme.palette.action.active,
        marginTop: '1vh'
    },
    link: {
        textDecoration: 'none'
    }
};

export function AccountScreen() {
    const { t: translate } = useTranslation();
    const album = useSelector((state: RootState) => state.album);
    const profile = useSelector((state: RootState) => state.profile);
    const [showProfile, setShowProfile] = useState(false);
    const [showReferralLink, setShowReferralLink] = useState(false);
    const [showInstallAppButton, setShowInstallAppButton] = useState(installAppEvent !== undefined);
    const dispatch = useDispatch();
    useEffect(() => {
        if (profile.userProfile) {
            (profile.userProfile.albums[0]?.media ?? []).map((media) => (new Image().src = media.url));
        }
    }, [profile.userProfile]);
    if (!profile.userProfile) {
        return <></>;
    }
    const userAlbum: Album = album.byId[profile.userProfile.albums?.[0]?.id];
    const profilePic: Media | undefined = userAlbum?.media?.[0];
    const shortScreen = window.innerHeight <= 750;
    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            {/*<Link to={`/account/profile/${profile.userProfile.id}`} style={{ textDecoration: 'none' }}>*/}
            <Box onClick={() => setShowProfile(true)} style={{ textDecoration: 'none' }}>
                <Avatar
                    src={profilePic?.url}
                    style={{ borderWidth: 4 }}
                    sx={{ height: shortScreen ? 150 : 200, width: shortScreen ? 150 : 200 }}
                />
            </Box>
            <Box component="div" sx={{ marginTop: 3 }}>
                <Typography color="secondary" align="center" variant="h4">
                    {profile.userProfile.username}
                </Typography>
            </Box>
            <Box
                sx={{ marginTop: showInstallAppButton ? 2 : 6 }}
                display="flex"
                flexDirection="row"
                justifyContent="space-around"
                width="100%"
            >
                <Zoom in={true}>
                    <Link to="/settings" style={style.link}>
                        <Box sx={style.iconContainer}>
                            <Box style={style.iconBox}>
                                <ManageAccounts style={style.icon} color="action" />
                            </Box>
                            <Typography style={style.iconLabel} align="center" variant="h6">
                                Settings
                            </Typography>
                        </Box>
                    </Link>
                </Zoom>
                <ImageInput
                    onComplete={(file, cropped) =>
                        dispatch(
                            createAlbumImage({
                                album: userAlbum,
                                croppedArea: cropped,
                                file
                            })
                        )
                    }
                >
                    <Zoom in={true} style={{ transitionDelay: '50ms' }}>
                        <Box sx={{ ...style.iconContainer, marginTop: '1.5em' }}>
                            <Badge
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                overlap="circular"
                                badgeContent={
                                    <Add
                                        color="secondary"
                                        style={{
                                            backgroundColor: theme.palette.common.white,
                                            borderRadius: '50%',
                                            boxShadow: '1px 1px 1px rgba(0,0,0,0.2)'
                                        }}
                                    />
                                }
                            >
                                <Box style={style.iconBoxAddImage}>
                                    <CameraAltRounded style={style.iconAddImage} />
                                </Box>
                            </Badge>
                            <Typography style={style.iconLabel} align="center" variant="h6">
                                Add Media
                            </Typography>
                        </Box>
                    </Zoom>
                </ImageInput>
                <Zoom in={true} style={{ transitionDelay: '100ms' }}>
                    <Link to="/account/edit" style={style.link}>
                        <Box sx={style.iconContainer}>
                            <Box style={style.iconBox}>
                                <CreateOutlined style={style.icon} color="action" />
                            </Box>
                            <Typography style={style.iconLabel} align="center" variant="h6">
                                Edit Info
                            </Typography>
                        </Box>
                    </Link>
                </Zoom>
            </Box>
            <Box sx={{ marginTop: showInstallAppButton ? 1 : 6, paddingLeft: 1, paddingRight: 1 }}>
                <Paper
                    elevation={2}
                    sx={{ padding: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => setShowReferralLink(true)}
                >
                    <Typography sx={{ flexGrow: 1 }} color="primary" variant="body1" gutterBottom>
                        {translate('account.invite', { appName: process.env.REACT_APP_META_NAME })}
                    </Typography>
                    <Box sx={{ flexShrink: 1 }}>
                        <MuiAvatar sx={(theme) => ({ backgroundColor: theme.palette.primary.main })}>
                            <Share />
                        </MuiAvatar>
                    </Box>
                </Paper>
                {showInstallAppButton && (
                    <Paper
                        elevation={2}
                        sx={{
                            padding: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 3,
                            cursor: 'pointer'
                        }}
                        onClick={async () => {
                            await installAppPrompt();
                            setShowInstallAppButton(false);
                        }}
                    >
                        <Typography sx={{ flexGrow: 1 }} color="secondary" variant="body1" gutterBottom>
                            {translate('account.install', { appName: process.env.REACT_APP_META_NAME })}
                        </Typography>
                        <Box sx={{ flexShrink: 1 }}>
                            <MuiAvatar
                                sx={(theme) => ({ backgroundColor: theme.palette.secondary.main })}
                                onClick={() => setShowReferralLink(true)}
                            >
                                <InstallMobile />
                            </MuiAvatar>
                        </Box>
                    </Paper>
                )}
            </Box>
            <CreateReferralLinkDialog open={showReferralLink} onClose={() => setShowReferralLink(false)} />
            <AnimatePresence>
                {showProfile && (
                    <Box
                        key={profile.userProfile.id}
                        component={motion.div}
                        initial={{
                            position: 'absolute',
                            top: '50%',
                            bottom: '50%',
                            left: '50%',
                            right: '50%',
                            opacity: 0
                        }}
                        animate={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            opacity: 1
                        }}
                        exit={{
                            position: 'absolute',
                            top: '50%',
                            bottom: '50%',
                            left: '50%',
                            right: '50%',
                            opacity: 0
                        }}
                        sx={{ zIndex: 10 }}
                    >
                        <LikeProvider profile={profile.userProfile}>
                            <ThemedSwipeCard
                                profile={profile.userProfile}
                                onDetailClose={() => setShowProfile(false)}
                                showDetail
                                showControls={false}
                            />
                        </LikeProvider>
                    </Box>
                )}
            </AnimatePresence>
        </Box>
    );
}
