import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';
import { ProfileQuestionResponse } from '../profileQuestionResponse';
import { Profile } from '../profile';
import { ProfileQuestion } from '../profileQuestion';

export const profilesByIdSelector = (state: RootState) => state.profile.byId;
export const profileSelector = (state: RootState, profile: number) => profilesByIdSelector(state)[profile];

const profileResponseSelector = (
    state: RootState,
    props: { profile: Profile; question: ProfileQuestion }
): ProfileQuestionResponse | undefined =>
    state.profileQuestionResponse.byProfile[props.profile.id]?.[props.question.id];
export const makeProfileResponseSelector = () =>
    createSelector(profileResponseSelector, (profileResponse) => profileResponse);
