import http from '../utils/http';
import { useDispatch } from 'react-redux';
import { retrieveProfiles } from '../entities/profile';

function deleteAllPasses(onRefresh: () => void) {
    http.create('/fast/match/passes/delete-all').then(onRefresh);
}

export function useRefreshProfiles() {
    const dispatch = useDispatch();
    return () => deleteAllPasses(() => dispatch(retrieveProfiles()));
}
