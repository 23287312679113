import { Box, Grid, Paper, ThemeProvider, Typography, useTheme } from '@mui/material';
import { AnimatePresence, motion, useMotionValue, useTransform } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Matched } from '../components/match';
import { retrieveLikes } from '../entities/likes';
import { Profile } from '../entities/profile';
import { selectLikesWithProfiles } from '../entities/selectors/likes';
import { Trans } from 'react-i18next';
import { Flare, FlareIcon, getProfileFlares, ThemedSwipeCard } from '../swipe/swipe';
import { useNavigate } from 'react-router-dom';
import { LikeContext, LikeProvider } from './likeContext';

import defaultProfilePic from '../assets/default-profile-pic.png';
import { createTheme } from '@mui/material/styles';

const swipeConfidenceThreshold = 10000;

interface LikeCardProps {
    profile: Profile;
    onLike: () => void;
}
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};

export function ThemedLikeCard(props: LikeCardProps) {
    const [flare, setFlare] = useState<Flare | undefined>();
    useEffect(() => {
        if (props.profile.flare !== undefined) {
            getProfileFlares((flares) => setFlare(flares[props.profile.flare!]));
        }
    }, []);

    if (flare) {
        const profileFlareTheme = createTheme({ ...flare.theme });
        return (
            <ThemeProvider theme={profileFlareTheme}>
                <LikeCard {...props} />
            </ThemeProvider>
        );
    }
    return <LikeCard {...props} />;
}

function LikeCard(props: LikeCardProps) {
    const likeContext = useContext(LikeContext);
    const { profile } = props;
    const [detailed, setDetailed] = useState(false);
    const x = useMotionValue(0);
    const likeOpacity = useTransform(x, [0, 100], [0, 1]);
    const dislikeOpacity = useTransform(x, [-100, 0], [1, 0]);
    const theme = useTheme();
    return (
        <Grid item xs={6} key={profile.id}>
            <Paper
                component={motion.div}
                style={{ x }}
                onContextMenu={(event) => event.preventDefault()}
                sx={{
                    position: 'relative',
                    overflow: 'hidden'
                }}
                drag="x"
                dragSnapToOrigin
                whileDrag={{ zIndex: 10 }}
                onDragEnd={(e, { offset, velocity }) => {
                    const swipe = swipePower(offset.x, velocity.x);
                    if (swipe < -swipeConfidenceThreshold) {
                        likeContext?.pass();
                    } else if (swipe > swipeConfidenceThreshold) {
                        likeContext?.like();
                        props.onLike?.();
                    }
                }}
            >
                {theme.profileFlare && (
                    <Box
                        sx={{
                            pointerEvents: 'none',
                            position: 'absolute',
                            zIndex: 1,
                            height: '100%',
                            width: '100%',
                            border: `4px solid ${theme.profileFlare.color}`
                        }}
                    />
                )}
                <Box onClick={() => setDetailed(true)} sx={{ aspectRatio: 2 / 3 }}>
                    <img
                        src={profile.albums?.[0].media?.[0]?.url ?? defaultProfilePic}
                        draggable={false}
                        height="100%"
                    />
                    <motion.div
                        style={{
                            position: 'absolute',
                            top: 10,
                            left: '10%',
                            fontSize: '5em',
                            transform: 'rotate(-20deg)',
                            zIndex: 2,
                            opacity: likeOpacity
                        }}
                    >
                        ❤️
                    </motion.div>
                    <motion.div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: '15%',
                            fontSize: '5em',
                            transform: 'rotate(70deg)',
                            zIndex: 2,
                            opacity: dislikeOpacity
                        }}
                    >
                        👋
                    </motion.div>
                </Box>
                {theme.profileFlare && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 3,
                            left: 3,
                            backgroundColor: 'white',
                            display: 'flex',
                            borderBottom: `3px solid ${theme.profileFlare.color}`,
                            borderRight: `3px solid ${theme.profileFlare.color}`,
                            borderRadius: '0 50% 0% 50%',
                            padding: '6px'
                        }}
                    >
                        <FlareIcon
                            style={{ fontSize: '1.5em', lineHeight: '1.25' }}
                            icon={theme.profileFlare?.icon}
                            horizontalFlip
                        />
                    </Box>
                )}
                <Paper
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: theme.profileFlare ? 3 : 0,
                        right: theme.profileFlare ? 3 : 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        paddingTop: 1.5,
                        paddingBottom: 1.5,
                        pointerEvents: 'none'
                    }}
                >
                    <Typography color="primary" sx={{ fontSize: '0.9em', lineHeight: 1 }}>
                        {profile.username}
                    </Typography>
                    <Typography color="secondary" sx={{ fontSize: '0.75em', lineHeight: 1 }}>
                        {profile.location?.name} - {profile.age}
                    </Typography>
                </Paper>
            </Paper>
            <AnimatePresence>
                {detailed && (
                    <Box
                        key={props.profile.id}
                        component={motion.div}
                        initial={{
                            position: 'absolute',
                            top: '50%',
                            bottom: '50%',
                            left: '50%',
                            right: '50%',
                            opacity: 0
                        }}
                        animate={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            opacity: 1
                        }}
                        exit={{
                            position: 'absolute',
                            top: '50%',
                            bottom: '50%',
                            left: '50%',
                            right: '50%',
                            opacity: 0
                        }}
                        sx={{ zIndex: 10 }}
                    >
                        <LikeProvider profile={props.profile}>
                            <ThemedSwipeCard
                                profile={props.profile}
                                onDetailClose={() => setDetailed(false)}
                                showDetail
                                onLike={() => {
                                    setDetailed(false);
                                    props.onLike?.();
                                }}
                                onPass={() => setDetailed(false)}
                            />
                        </LikeProvider>
                    </Box>
                )}
            </AnimatePresence>
        </Grid>
    );
}

export function LikeScreen() {
    const [matched, setMatched] = useState(false);
    const [matchedProfile, setMatchedProfile] = useState<Profile | undefined>();
    const likes = useSelector(selectLikesWithProfiles);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(retrieveLikes());
    }, []);

    const hasLikes = likes.length > 0;
    return (
        <Box style={{ padding: 4, height: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
            {hasLikes && (
                <Grid container spacing={1}>
                    {likes.map((like) => (
                        <LikeProvider key={like.profile.id} profile={like.profile}>
                            <ThemedLikeCard
                                profile={like.profile}
                                onLike={() => {
                                    setMatched(true);
                                    setMatchedProfile(like.profile);
                                }}
                            />
                        </LikeProvider>
                    ))}
                </Grid>
            )}
            {!hasLikes && (
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        height: '100%'
                    }}
                >
                    <Typography variant="h5" color="secondary" align="center">
                        <Trans i18nKey="likes.noLikes" />
                    </Typography>
                </Box>
            )}
            {matched && matchedProfile && (
                <Box sx={{ position: 'absolute', zIndex: 100, top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Matched
                        profile={matchedProfile}
                        onIgnore={() => {
                            setMatched(false);
                            setMatchedProfile(undefined);
                        }}
                        onShowMatch={(match) => navigate(`/matches/${match.id}`)}
                    />
                </Box>
            )}
        </Box>
    );
}
