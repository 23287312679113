import { Card, CardContent, CardMedia } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { Media } from '../entities/album';
import defaultProfilePic from '../assets/default-profile-pic.png';

export class MediaCardWithAspectRatio extends React.PureComponent<PropsWithChildren<void>> {
    public render(): React.ReactNode {
        return (
            <Card variant="outlined" style={{ aspectRatio: '2/3' }}>
                <CardContent style={{ aspectRatio: '2/3', padding: 2 }}>{this.props.children}</CardContent>
            </Card>
        );
    }
}

interface MediaCardProps {
    media?: Media | undefined;
}

export function MediaCard(props: MediaCardProps) {
    const { media } = props;
    return (
        <MediaCardWithAspectRatio>
            <CardMedia
                component="img"
                height="100%"
                image={media?.url ?? defaultProfilePic}
                style={{ borderRadius: 4, touchAction: 'none', zIndex: -1 }}
                draggable={false}
            />
        </MediaCardWithAspectRatio>
    );
}
