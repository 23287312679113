import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { albumReducer } from './album';
import { chatReducer } from './chat';
import { likeReducer } from './likes';
import { messageReducer } from './message';
import { profileQuestionReducer } from './profileQuestion';
import { profileQuestionResponseReducer } from './profileQuestionResponse';
import { userReducer } from './user';
import { profileReducer } from './profile';
import { referralsReducer } from './referrals';

const rootReducer = combineReducers({
    album: albumReducer,
    chat: chatReducer,
    like: likeReducer,
    message: messageReducer,
    profile: profileReducer,
    profileQuestion: profileQuestionReducer,
    profileQuestionResponse: profileQuestionResponseReducer,
    referrals: referralsReducer,
    user: userReducer
});

export const store = configureStore({
    reducer: rootReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export interface AppDispatchProp {
    dispatch: AppDispatch;
}
