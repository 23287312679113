import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    OutlinedInput,
    rgbToHex,
    Typography,
    useTheme
} from '@mui/material';
import { RootState } from '../../entities/store';
import { createReferralLink } from '../../entities/referrals';
import { Close, ContentPasteOutlined } from '@mui/icons-material';
import { Trans, useTranslation } from 'react-i18next';
import QRCode from 'qrcode';

export function CreateReferralLink() {
    const dispatch = useDispatch();
    const referrals = useSelector((state: RootState) => state.referrals);
    const inputRef = useRef<HTMLInputElement>(null);
    const [copied, setCopied] = useState(false);
    const qrImage = useRef<HTMLImageElement>(null);
    const { t: translate } = useTranslation();
    const theme = useTheme();

    function dispatchCreateReferralLink() {
        if (referrals.status !== 'creating') {
            dispatch(createReferralLink());
        }
    }

    useEffect(() => {
        dispatchCreateReferralLink();
    }, []);
    let referralLink = 'No Link';
    if (referrals.lastCreatedReferralLink) {
        referralLink = `${process.env.REACT_APP_URL}/signup/${referrals.lastCreatedReferralLink?.id}`;
    }
    QRCode.toDataURL(referralLink, {
        errorCorrectionLevel: 'L',
        width: 400,
        margin: 1,
        color: { dark: rgbToHex(theme.palette.secondary.main) }
    }).then((dataUrl) => {
        if (qrImage?.current) {
            qrImage.current.src = dataUrl;
        }
    });
    return (
        <>
            <Typography
                gutterBottom
                variant="body1"
                color="primary.dark"
                sx={{ '& br': { display: 'block', marginBottom: 1 } }}
            >
                <Trans i18nKey="createReferralLink.text" values={{ appName: process.env.REACT_APP_META_NAME }} />
            </Typography>
            <Box
                sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    border: `2px solid ${theme.palette.secondary.main}`,
                    borderRadius: 4,
                    padding: 1,
                    marginBottom: 2,
                    marginTop: 2
                })}
            >
                <img style={{ width: '100%' }} ref={qrImage} />
            </Box>
            <OutlinedInput
                id="outlined-basic"
                value={referralLink}
                fullWidth
                sx={{ fontSize: '0.85em', marginBottom: 2 }}
                inputRef={inputRef}
                inputProps={{
                    contentEditable: false,
                    onClick: () => inputRef.current?.select(),
                    onTouchStart: () => inputRef.current?.select(),
                    onTouchEnd: () => inputRef.current?.select(),
                    onDoubleClick: () => inputRef.current?.select(),
                    onKeyDown: () => inputRef.current?.select(),
                    onKeyUp: () => inputRef.current?.select()
                }}
                endAdornment={
                    <InputAdornment position="end" sx={{ touchAction: 'none' }}>
                        <IconButton
                            sx={(theme) => ({ [theme.breakpoints.down('md')]: { display: 'none' } })}
                            aria-label="copy signup referral link"
                            onClick={() => {
                                navigator.clipboard.writeText(referralLink);
                                if (!copied) {
                                    setCopied(true);
                                    setTimeout(() => setCopied(false), 3000);
                                }
                            }}
                            edge="end"
                        >
                            <ContentPasteOutlined />
                        </IconButton>
                        <IconButton
                            sx={(theme) => ({ [theme.breakpoints.up('md')]: { display: 'none' } })}
                            aria-label="copy signup referral link"
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    translate('createReferralLink.clipboardCopyText', {
                                        appName: process.env.REACT_APP_META_NAME,
                                        link: referralLink
                                    })
                                );
                            }}
                            edge="end"
                        >
                            <ContentPasteOutlined />
                        </IconButton>
                    </InputAdornment>
                }
            />
            <Typography
                sx={{
                    opacity: copied ? 1 : 0,
                    color: '#888',
                    padding: 1,
                    [theme.breakpoints.down('sm')]: { display: 'none' }
                }}
                textAlign="right"
            >
                Copied to Clipboard
            </Typography>
            <Button onClick={dispatchCreateReferralLink} fullWidth size="large" variant="contained" color="secondary">
                Create Another
            </Button>
        </>
    );
}

interface CreateReferralLinkDialogProps {
    open: boolean;
    onClose: () => void;
}

export function CreateReferralLinkDialog(props: CreateReferralLinkDialogProps) {
    const { t: translate } = useTranslation();
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle sx={{ margin: 0, padding: 2, paddingBottom: 0 }} color="secondary">
                {translate('createReferralLink.title')}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                }}
            >
                <Close />
            </IconButton>
            <DialogContent>
                <CreateReferralLink />
            </DialogContent>
        </Dialog>
    );
}
