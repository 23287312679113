import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http, { ResponseError } from '../utils/http';

export interface ReferralLink {
    expires: string;
    id: string;
}

export interface ReferralLinkRaw {
    expires: string;
    id: string;
    max_referrals: number;
    referrer: number;
}

export interface ReferralState {
    lastCreatedReferralLink: ReferralLink | undefined;
    status: 'init' | 'creating' | 'complete' | 'error';
}

const initialState: ReferralState = {
    lastCreatedReferralLink: undefined,
    status: 'init'
};

export const createReferralLink = createAsyncThunk<ReferralLinkRaw, void, { rejectValue: ResponseError }>(
    'referrals/create',
    async (_, thunkApi) => {
        try {
            return await http.create('/referrals/create/');
        } catch (error) {
            return thunkApi.rejectWithValue(error as ResponseError);
        }
    }
);

export const referralsSlice = createSlice({
    name: 'referrals',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createReferralLink.fulfilled, (state, action) => {
                state.lastCreatedReferralLink = { id: action.payload.id, expires: action.payload.expires };
                state.status = 'complete';
            })
            .addCase(createReferralLink.pending, (state) => {
                state.status = 'creating';
            })
            .addCase(createReferralLink.rejected, (state) => {
                state.status = 'error';
            });
    }
});

export const referralsReducer = referralsSlice.reducer;
