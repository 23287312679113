import React, { ReactNode } from 'react';
import logo from '../assets/logo.png';
import { theme } from '../theme';
import { Box, Card } from '@mui/material';

export function AuthContainer(props: { children: ReactNode; logo?: true | undefined }) {
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: props.logo ? 'initial' : 'center',
                minHeight: '100%'
            }}
        >
            {props.logo && (
                <img
                    src={logo}
                    style={{ height: 'auto', width: 200, padding: theme.spacing(2) }}
                    alt={process.env.REACT_APP_TITLE}
                />
            )}
            <Card sx={{ width: '80vw', [theme.breakpoints.up('md')]: { maxWidth: theme.breakpoints.values['sm'] } }}>
                {props.children}
            </Card>
        </Box>
    );
}
