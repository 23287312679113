import React, { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../entities/store';
import {
    loginUser,
    logoutUser,
    registerUser,
    retrieveUser,
    UserLoginData,
    UserRegisterData,
    UserState
} from '../entities/user';
import { websocket } from '../providers';
import { retrieveUserProfile } from '../entities/profile';
import { retrieveUserProfileQuestions } from '../entities/profileQuestion';

const onLoginCallbacks: Array<() => void> = [];
const onLogoutCallbacks: Array<() => void> = [];

export function registerLoginCallback(callback: () => void): () => void {
    const index = onLoginCallbacks.push(callback) - 1;
    return () => {
        onLoginCallbacks.splice(index, 1);
    };
}
export function registerLogoutCallback(callback: () => void): () => void {
    const index = onLogoutCallbacks.push(callback) - 1;
    return () => {
        onLogoutCallbacks.splice(index, 1);
    };
}

const AuthContext = createContext<{
    user: UserState;
    login: (data: UserLoginData) => void;
    logout: () => void;
    register: (data: UserRegisterData) => void;
}>({
    user: store.getState().user,
    login: () => {},
    logout: () => {},
    register: () => {}
});

export function AuthProvider(props: { children: ReactNode }) {
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user.status === 'init' && user.requestStatus === 'init') {
            dispatch(retrieveUser());
            dispatch(retrieveUserProfile());
            dispatch(retrieveUserProfileQuestions());
        }
    }, []);

    function login(data: UserLoginData) {
        dispatch(loginUser(data));
    }

    useEffect(() => {
        if (user.status === 'loggedIn') {
            websocket.connect();
            onLoginCallbacks.forEach((callback) => callback());
        } else if (user.status === 'loggedOut') {
            websocket.close();
            onLogoutCallbacks.forEach((callback) => callback());
        }
    }, [user.status]);

    function register(data: UserRegisterData) {
        dispatch(registerUser(data));
    }

    function logout() {
        dispatch(logoutUser());
    }

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
            register
        }),
        [user]
    );

    return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
}

export function useAuth() {
    return useContext(AuthContext);
}
