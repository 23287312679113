import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translate from './en/translate.json';

export const defaultNS = 'translate';

i18next.use(initReactI18next).init({
    lng: 'en', // if you're using a language detector, do not define the lng option
    debug: true,
    resources: {
        en: { translate }
    },
    defaultNS
});
