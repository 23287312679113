import { keyTransform, snakeToCamelString } from './object';

interface MultiplexedStreamData {
    stream: string;
    payload: never;
}
type MultiplexedStreamMessageEvent = MessageEvent<string>;
type WebSocketClientListenerCallback<T = never> = (data: T) => void;
interface WebSocketClientListener {
    callback: WebSocketClientListenerCallback;
    stream: string;
}

export class WebSocketClient {
    public websocket?: WebSocket;
    public websocketUrl: string;
    public listeners: WebSocketClientListener[] = [];

    public constructor(websocketUrl: string) {
        this.websocketUrl = websocketUrl;
        this.connect();
    }

    public connect(): void {
        this.close();
        this.websocket = new WebSocket(this.websocketUrl);
        this.websocket.addEventListener('error', () => {
            setTimeout(() => {
                // do this check cause apparently Brave Flatpak considers a manual close as a fucking error
                // Firefox, Chromium FlatPak, Adroid Brave and Android Firefox don't treat a manual close
                // as a fucking error. Brilliant!
                if (this.websocket?.readyState === WebSocket.CLOSED) {
                    this.connect();
                }
            }, 1000);
        });
        this.websocket.addEventListener('message', this.receiveMessage);
    }

    public close(): void {
        this.websocket?.close();
    }

    public addEventListener<T>(stream: string, callback: WebSocketClientListenerCallback<T>): void {
        this.listeners.push({ callback, stream });
    }

    public receiveMessage = (event: MultiplexedStreamMessageEvent): void => {
        const data: MultiplexedStreamData = JSON.parse(event.data);
        this.listeners.forEach((listener) => {
            if (listener.stream === data.stream) {
                listener.callback(keyTransform(data.payload, snakeToCamelString));
            }
        });
    };

    public sendMessage = <T extends Record<string, unknown>>(stream: string, action: string, data: T) => {
        if (!this.websocket || [WebSocket.CLOSED, WebSocket.CLOSING].includes(this.websocket.readyState)) {
            this.connect();
        }
        this.websocket?.send(
            JSON.stringify({
                stream,
                payload: {
                    action,
                    request_id: 1,
                    ...data
                }
            })
        );
    };
}
