import { AnyAction, AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import http, { ResponseError } from '../utils/http';
import { Album, setAlbumsFromRaw } from './album';
import { ChatRaw } from './chat';
import { addProfile, blockProfile, Location, Profile, ProfileRaw, setProfileFromRaw } from './profile';
import { logoutUser } from './user';
import { websocket } from '../providers';
import { store } from './store';

interface CreateLikeRaw {
    profile: number;
    super_like: boolean;
}

interface CreatePassRaw {
    profile: number;
}

interface LikeRaw {
    id: number;
    profile: ProfileRaw;
    status: 'liked' | 'matched' | 'passed' | 'unmatched';
}

interface MatchRaw extends LikeRaw {
    chat_group: ChatRaw;
    latest_activity: string;
}

export interface Like {
    chat?: number;
    id: number;
    profile: number;
    status: 'liked' | 'matched' | 'passed' | 'unmatched';
}

export interface Match extends Like {
    chat?: number;
    latestActivity: string;
}

export interface LikesState {
    error?: ResponseError;
    likes: Like[];
    matches: Match[];
    requestStatus: 'init' | 'pending' | 'complete' | 'error';
}

const initialState: LikesState = {
    likes: [],
    matches: [],
    requestStatus: 'init'
};

export const retrieveLikes = createAsyncThunk<LikeRaw[], void, { rejectValue: ResponseError }>(
    'likes/retrieve',
    async (_, thunkApi) => {
        try {
            const likesRaw = await http.retrieve<LikeRaw[]>('/match/like-me/list/');
            likesRaw.forEach((likeRaw) => {
                const profileRaw = likeRaw.profile;
                const albumsRaw = profileRaw.albums;
                thunkApi.dispatch(setProfileFromRaw(profileRaw));
                thunkApi.dispatch(setAlbumsFromRaw(albumsRaw));
            });
            return likesRaw;
        } catch (error) {
            return thunkApi.rejectWithValue(error as ResponseError);
        }
    }
);

export const retrieveMatches = createAsyncThunk<MatchRaw[], void, { rejectValue: ResponseError }>(
    'likes/matches',
    async (_, thunkApi) => {
        try {
            const matchesRaw = await http.retrieve<MatchRaw[]>('/match/list/');
            matchesRaw.forEach((matchRaw) => {
                const profileRaw = matchRaw.profile;
                const albumsRaw = profileRaw.albums;
                thunkApi.dispatch(setProfileFromRaw(profileRaw));
                thunkApi.dispatch(setAlbumsFromRaw(albumsRaw));
            });
            return matchesRaw;
        } catch (error) {
            return thunkApi.rejectWithValue(error as ResponseError);
        }
    }
);

export const createLike = createAsyncThunk<LikeRaw, Profile, { rejectValue: ResponseError }>(
    'likes/like/create',
    async (profile, thunkApi) => {
        try {
            return await http.create<LikeRaw, CreateLikeRaw>('/match/like/create/', {
                data: {
                    profile: profile.id,
                    super_like: false
                }
            });
        } catch (error) {
            return thunkApi.rejectWithValue(error as ResponseError);
        }
    }
);

export const createPass = createAsyncThunk<LikeRaw, Profile, { rejectValue: ResponseError }>(
    'likes/pass/create',
    async (profile, thunkApi) => {
        try {
            return await http.create<LikeRaw, CreatePassRaw>('/match/pass/create/', {
                data: { profile: profile.id }
            });
        } catch (error) {
            return thunkApi.rejectWithValue(error as ResponseError);
        }
    }
);

type GenericAsyncThunk = AsyncThunk<unknown, unknown, never>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

const likesPendingAction = (action: AnyAction): action is PendingAction => {
    return action.type.startsWith('likes/') && action.type.endsWith('/pending');
};
const likesRejectedAction = (action: AnyAction): action is RejectedAction => {
    return action.type.startsWith('likes/') && action.type.endsWith('/rejected');
};

type CreateLikeAsyncThunk = AsyncThunk<LikeRaw, Profile, { rejectValue: ResponseError }>;
type CreateLikeFulfilledAction = ReturnType<CreateLikeAsyncThunk['fulfilled']>;
export const createLikeFulfilledAction = (action: AnyAction): action is CreateLikeFulfilledAction => {
    return [createLike.fulfilled.type, createPass.fulfilled.type].includes(action.type);
};

export const likeSlice = createSlice({
    name: 'likes',
    initialState,
    reducers: {
        addLike: (state, action: PayloadAction<Like>) => {
            state.likes.unshift(action.payload);
        },
        addMatch: (state, action: PayloadAction<Match>) => {
            state.matches.unshift(action.payload);
        },
        updateLatestActivity: (state, action: PayloadAction<Pick<Match, 'chat' | 'latestActivity'>>) => {
            state.matches.find((match) => match.chat === action.payload.chat)!.latestActivity =
                action.payload.latestActivity;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(logoutUser.fulfilled, (state) => {
                Object.assign(state, initialState);
            })
            .addCase(retrieveLikes.fulfilled, (state, action) => {
                state.likes = action.payload.map((rawLike) => ({
                    id: rawLike.id,
                    profile: rawLike.profile.id,
                    status: rawLike.status
                }));
                state.requestStatus = 'complete';
            })
            .addCase(retrieveMatches.fulfilled, (state, action) => {
                state.matches = action.payload.map((rawMatch) => ({
                    chat: rawMatch.chat_group.id,
                    id: rawMatch.id,
                    latestActivity: rawMatch.latest_activity,
                    profile: rawMatch.profile.id,
                    status: rawMatch.status
                }));
                state.requestStatus = 'complete';
            })
            .addCase(blockProfile.fulfilled, (state, action) => {
                state.likes = state.likes.filter((like) => like.profile !== action.meta.arg.profile);
                state.matches = state.matches.filter((match) => match.profile !== action.meta.arg.profile);
            })
            .addMatcher(createLikeFulfilledAction, (state, action) => {
                const rawLike = action.payload;
                const profile = action.meta.arg;
                state.likes = state.likes.filter((like) => like.profile !== profile.id);
                if (rawLike.status === 'matched') {
                    const rawMatch = rawLike as MatchRaw;
                    state.matches.unshift({
                        chat: rawMatch.chat_group.id,
                        latestActivity: rawMatch.latest_activity,
                        id: rawMatch.id,
                        profile: rawMatch.profile.id,
                        status: rawMatch.status
                    });
                }
                state.requestStatus = 'complete';
            })
            .addMatcher(likesPendingAction, (state) => {
                state.requestStatus = 'pending';
            })
            .addMatcher(likesRejectedAction, (state) => {
                state.requestStatus = 'error';
            });
    }
});

export const likeReducer = likeSlice.reducer;
export const addLike = likeSlice.actions.addLike;
export const addMatch = likeSlice.actions.addMatch;
export const updateLatestActivity = likeSlice.actions.updateLatestActivity;

type MatchWS = {
    chatGroup: number | null | undefined;
    id: number;
    latestActivity: string;
    profile: ProfileWS;
    status: 'liked' | 'matched' | 'passed' | 'unmatched';
};

type ProfileWS = {
    albums: Album[];
    id: number;
    isVisible: boolean;
    flare?: string | undefined;
    user: {
        age: number;
        username: string;
    };
    locationDetailed: Location;
};
websocket.addEventListener('match', (match: MatchWS) => {
    store.dispatch(
        addProfile({
            age: match.profile.user.age,
            albums: match.profile.albums,
            id: match.profile.id,
            isVisible: match.profile.isVisible,
            flare: match.profile.flare,
            username: match.profile.user.username,
            location: match.profile.locationDetailed
        })
    );
    store.dispatch(setAlbumsFromRaw(match.profile.albums));
    if (match.status === 'liked') {
        store.dispatch(
            addLike({
                id: match.id,
                profile: match.profile.id,
                status: match.status
            })
        );
    } else if (match.status === 'matched') {
        store.dispatch(
            addMatch({
                id: match.id,
                profile: match.profile.id,
                latestActivity: match.latestActivity,
                status: match.status,
                chat: match.chatGroup ?? undefined
            })
        );
    }
});
