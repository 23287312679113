import React, { ReactNode, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import './i18n/config';
import { LoginScreen } from './auth/login';
import styled from '@emotion/styled';
import { Alert, Box, CircularProgress, Container } from '@mui/material';
import { AccountScreen } from './account/account';
import { RegisterScreen } from './auth/register';
import { WelcomeScreen } from './auth/welcome';
import { useAuth } from './auth/auth';
import { AppBottomNavigation } from './navigation/navigation';
import { EditInfoScreen } from './account/editInfo';
import { SwipeScreen } from './swipe/swipe';
import { LikeScreen } from './likes/likes';
import { MatchesScreen } from './matches/matches';
import { MessageScreen } from './matches/messages';
import { ReferralInfo } from './auth/referral';
import { SettingsScreen } from './account/settings';

const AppContainer = styled(Container)({
    flex: 1,
    overflowY: 'scroll',
    padding: 0,
    position: 'relative'
});

function DevAppAlert() {
    const [showAlert, setShowAlert] = useState(true);
    if (!showAlert) {
        return null;
    }
    return (
        <Alert
            severity="warning"
            onClose={() => setShowAlert(false)}
            sx={(theme) => ({
                position: 'absolute',
                top: 8,
                left: 40,
                right: 40,
                zIndex: 100,
                [theme.breakpoints.down('sm')]: { display: 'none' }
            })}
        >
            Use a mobile device for the best Pineapple Love 🍍❤️ experience.
            <hr />
            The desktop and tablet experience will be better in the New Year!
        </Alert>
    );
}

function AuthenticatedRoute(props: { children: ReactNode }) {
    const auth = useAuth();
    if (auth.user.status === 'loggedOut') {
        return <Navigate to="/login" replace={true} />;
    }
    return <>{props.children}</>;
}

function RedirectAuthenticatedRoute(props: { children: ReactNode }) {
    const auth = useAuth();
    if (auth.user.status === 'loggedIn') {
        return <Navigate to="/swipe" replace={true} />;
    }
    return <>{props.children}</>;
}

function NavigateOnPush() {
    const navigate = useNavigate();
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', (event) => {
                if (event.data?.action === 'redirect-from-notificationclick') {
                    navigate(event.data.url);
                }
            });
        }
    }, []);
    return null;
}

export default function App() {
    const auth = useAuth();
    return (
        <BrowserRouter>
            <NavigateOnPush />
            <AppContainer maxWidth="sm">
                {auth.user.status === 'init' && (
                    <Box
                        style={{
                            position: 'fixed',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                {auth.user.status !== 'init' && (
                    <Routes>
                        <Route
                            path="/login"
                            element={
                                <RedirectAuthenticatedRoute>
                                    <LoginScreen />
                                </RedirectAuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/signup/:referralCode"
                            element={
                                <RedirectAuthenticatedRoute>
                                    <RegisterScreen />
                                </RedirectAuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/signup"
                            element={
                                <RedirectAuthenticatedRoute>
                                    <ReferralInfo />
                                </RedirectAuthenticatedRoute>
                            }
                        />
                        <Route path="/welcome" element={<WelcomeScreen />} />
                        <Route
                            path="/account"
                            element={
                                <AuthenticatedRoute>
                                    <AccountScreen />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/settings"
                            element={
                                <AuthenticatedRoute>
                                    <SettingsScreen />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/account/edit"
                            element={
                                <AuthenticatedRoute>
                                    <EditInfoScreen />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/swipe"
                            element={
                                <AuthenticatedRoute>
                                    <SwipeScreen />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/likes"
                            element={
                                <AuthenticatedRoute>
                                    <LikeScreen />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/matches/:matchId"
                            element={
                                <AuthenticatedRoute>
                                    <MessageScreen />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/matches"
                            element={
                                <AuthenticatedRoute>
                                    <MatchesScreen />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="*"
                            element={<Navigate to={auth.user.status === 'loggedIn' ? '/swipe' : '/login'} replace />}
                        />
                    </Routes>
                )}
                <DevAppAlert />
            </AppContainer>
            <AppBottomNavigation />
        </BrowserRouter>
    );
}
