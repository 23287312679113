import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveMatches } from '../entities/likes';
import { selectMatch } from '../entities/selectors/likes';
import { RootState } from '../entities/store';
import { websocket } from '../providers';
import { messagesSelector } from './selectors';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar } from '../components/avatar';
import { ArrowBack, Send } from '@mui/icons-material';
import './messages.css';
import { ThemedSwipeCard } from '../swipe/swipe';
import { AnimatePresence, motion } from 'framer-motion';
import { LikeProvider } from '../likes/likeContext';
import { Trans } from 'react-i18next';
import { BlockProfile } from '../components/blockProfile';

export function MessageScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const match = useSelector((state: RootState) => selectMatch(state, parseInt(params.matchId ?? '-1', 10)));
    const messages = useSelector((state: RootState) => messagesSelector(state, { chatId: match?.chat ?? -1 }));
    const user = useSelector((state: RootState) => state.user.user);
    const [messageInput, setMessageInput] = useState('');
    const [showProfile, setShowProfile] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const textFieldRef = useRef<HTMLTextAreaElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const sendMessage = () => {
        if (match?.chat && messageInput.trim().length) {
            websocket.sendMessage('chat', 'send_message', {
                message: messageInput,
                group_id: match.chat
            });
            setMessageInput('');
        }
        textFieldRef.current?.focus();
    };

    useEffect(() => {
        dispatch(retrieveMatches());
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden'
            }}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    flexShrink: 1,
                    marginBottom: 2,
                    marginTop: 2
                }}
            >
                <ArrowBack
                    sx={{
                        marginLeft: 2,
                        marginRight: 2
                    }}
                    onClick={() => navigate(-1)}
                />
                <Avatar
                    alt={match?.profile?.username}
                    sx={{
                        height: 40,
                        marginLeft: 2,
                        marginRight: 2,
                        width: 40
                    }}
                    src={match?.profile?.albums[0].media?.[0]?.url}
                    onClick={() => setShowProfile(true)}
                />
                <Typography sx={{ flexGrow: 1 }} onClick={() => setShowProfile(true)}>
                    {match?.profile?.username}
                </Typography>
                {match?.profile && (
                    <Box alignSelf="flex-end" sx={{ flexShrink: 1, marginRight: 2 }}>
                        <LikeProvider profile={match.profile}>
                            <BlockProfile button onBlock={() => navigate(-1)} />
                        </LikeProvider>
                    </Box>
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    padding: '1vw'
                }}
            >
                {messages.length === 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            padding: 2
                        }}
                    >
                        <Typography color="secondary" variant="h5" align="center">
                            <Trans i18nKey="messages.noMessages" />
                        </Typography>
                    </Box>
                )}
                {messages.map((message, index) => {
                    let messageIsEmoji;
                    try {
                        messageIsEmoji = /^(\p{Extended_Pictographic})*$/u.test(message.message);
                    } catch {
                        messageIsEmoji = false;
                    }
                    const className = [
                        messageIsEmoji ? 'messageEmoji' : 'messageBubble',
                        messageIsEmoji
                            ? undefined
                            : message.author === user?.id
                            ? 'messageBubbleRight'
                            : 'messageBubbleLeft',
                        message.author === user?.id ? 'messageRight' : 'messageLeft'
                    ].join(' ');
                    return (
                        <Typography className={className} key={index}>
                            {message.message}
                        </Typography>
                    );
                })}
                <div ref={messagesEndRef} />
            </Box>
            <Box
                sx={{
                    flexShrink: 1,
                    margin: '2vh'
                }}
            >
                <TextField
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment onClick={() => sendMessage()} position="end">
                                <Send color={messageInput.trim().length > 0 ? 'secondary' : 'disabled'} />
                            </InputAdornment>
                        )
                    }}
                    multiline
                    onChange={(event) => setMessageInput(event.target.value)}
                    onFocus={() => setTimeout(() => scrollToBottom(), 300)}
                    placeholder="Send Message/Or don't"
                    inputRef={textFieldRef}
                    maxRows={6}
                    value={messageInput}
                    variant="outlined"
                />
            </Box>
            <AnimatePresence>
                {match?.profile && showProfile && (
                    <Box
                        key={match.profile.id}
                        component={motion.div}
                        initial={{
                            position: 'absolute',
                            top: '50%',
                            bottom: '50%',
                            left: '50%',
                            right: '50%',
                            opacity: 0
                        }}
                        animate={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            opacity: 1
                        }}
                        exit={{
                            position: 'absolute',
                            top: '50%',
                            bottom: '50%',
                            left: '50%',
                            right: '50%',
                            opacity: 0
                        }}
                        sx={{ zIndex: 10 }}
                    >
                        <LikeProvider profile={match.profile}>
                            <ThemedSwipeCard
                                profile={match.profile}
                                onDetailClose={() => setShowProfile(false)}
                                showDetail
                                showControls={false}
                            />
                        </LikeProvider>
                    </Box>
                )}
            </AnimatePresence>
        </Box>
    );
}
