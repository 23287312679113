import { Profile } from '../entities/profile';
import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion, wrap } from 'framer-motion';
import { Box } from '@mui/material';
import defaultAvatar from '../assets/default-profile-pic.png';

const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};

export function ProfileAlbum(props: { profile: Profile; disabled: boolean }) {
    const albumImages = (props.profile.albums[0]?.media ?? []).map((media) => media.url);
    useEffect(() => {
        (props.profile.albums[0]?.media ?? []).map((media) => (new Image().src = media.url));
    }, []);
    if (!albumImages.length) {
        albumImages.push(defaultAvatar);
    }
    const [[page, direction], setPage] = useState([0, 0]);
    const imageIndex = wrap(0, albumImages.length, page);
    const paginate = (newDirection: number) => {
        setPage([page + newDirection, newDirection]);
    };

    return (
        <Box style={{ position: 'relative', aspectRatio: 2 / 3 }}>
            <AnimatePresence initial={false} custom={direction}>
                <motion.img
                    key={page}
                    src={albumImages[imageIndex]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                        x: { type: 'spring', stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 }
                    }}
                    drag={props.disabled || albumImages.length <= 1 ? false : 'x'}
                    dragConstraints={{ left: 0, right: 0 }}
                    dragSnapToOrigin
                    dragElastic={1}
                    onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);
                        if (swipe < -swipeConfidenceThreshold && page < albumImages.length - 1) {
                            paginate(1);
                        } else if (swipe > swipeConfidenceThreshold && page > 0) {
                            paginate(-1);
                        }
                    }}
                    style={{
                        width: '100%',
                        height: 'auto',
                        position: 'absolute',
                        pointerEvents: props.disabled ? 'none' : 'initial'
                    }}
                />
                {!props.disabled && albumImages.length > 1 && (
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            position: 'absolute',
                            top: 0,
                            width: '100%',
                            height: 20,
                            zIndex: 1
                        }}
                    >
                        {albumImages.map((url, index) => (
                            <Box
                                key={index}
                                style={{
                                    opacity: 0.7,
                                    backgroundColor: page % albumImages.length === index ? 'white' : 'black',
                                    flex: 1,
                                    display: 'inline',
                                    margin: 5,
                                    borderRadius: 10,
                                    border: `1px solid ${page % albumImages.length === index ? 'black' : 'white'}`,
                                    filter: `drop-shadow(0,0,1px,${
                                        Math.abs(page) % albumImages.length === index ? 'white' : 'black'
                                    })`
                                }}
                            />
                        ))}
                    </Box>
                )}
            </AnimatePresence>
        </Box>
    );
}
