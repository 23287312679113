import { Box, Button, Paper, Typography } from '@mui/material';
import React from 'react';
import { Profile } from '../entities/profile';
import { useSelector } from 'react-redux';
import { MatchWithProfile, selectMatchByProfile } from '../entities/selectors/likes';
import { RootState } from '../entities/store';
import { Avatar } from './avatar';

interface MatchedProps {
    onIgnore: (profile: Profile) => void;
    onShowMatch: (match: MatchWithProfile) => void;
    profile: Profile;
}

export function Matched(props: MatchedProps) {
    const { onIgnore, onShowMatch, profile } = props;
    const match = useSelector((state: RootState) => selectMatchByProfile(state, profile.id));
    const profileImage = profile.albums[0].media?.[0]?.url;
    return (
        <Paper
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                top: 0
            }}
        >
            <Typography align="center" color="primary" variant="h3" fontSize="2.8rem">
                You matched!
            </Typography>
            <Box style={{ marginBottom: '5vh', marginTop: '5vh' }}>
                <Avatar src={profileImage} style={{ height: '40vw', width: '40vw' }} />
            </Box>
            <Typography align="center" color="secondary" variant="h3" fontSize="2.3rem">
                {profile.username}
            </Typography>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    marginTop: '4em',
                    width: '100%'
                }}
            >
                <Button
                    sx={{ padding: 2, width: '45%', fontSize: '1.05rem', lineHeight: '1.5' }}
                    color="primary"
                    onClick={() => onIgnore(profile)}
                    variant="contained"
                >
                    Ghost 👻
                </Button>
                <Button
                    sx={{ padding: 2, width: '45%', fontSize: '1.05rem', lineHeight: '1.5' }}
                    color="secondary"
                    onClick={() => match && onShowMatch(match)}
                    variant="contained"
                >
                    💬 Message
                </Button>
            </Box>
        </Paper>
    );
}
