import { createSelector } from '@reduxjs/toolkit';
import { Like, Match } from '../likes';
import { Profile } from '../profile';
import { RootState } from '../store';
import { profilesByIdSelector } from './profile';

export interface LikeWithProfile {
    id: Like['id'];
    profile: Profile;
    status: Like['status'];
}

export interface MatchWithProfile {
    chat: Match['chat'];
    id: Match['id'];
    latestActivity: Match['latestActivity'];
    profile: Profile;
    status: Match['status'];
}

const getLikes = (state: RootState) => state.like.likes;
const getMatches = (state: RootState) => state.like.matches;
export const selectLikesWithProfiles = createSelector(
    [getLikes, profilesByIdSelector],
    (likes: Like[], profilesById: Record<number, Profile>): LikeWithProfile[] => {
        return likes.map((like) => ({
            id: like.id,
            profile: profilesById[like.profile],
            status: like.status
        }));
    }
);

export const selectMatchesWithProfiles = createSelector(
    [getMatches, profilesByIdSelector],
    (matches: Match[], profilesById: Record<number, Profile>): MatchWithProfile[] => {
        return matches.map((match) => ({
            chat: match.chat,
            id: match.id,
            latestActivity: match.latestActivity,
            profile: profilesById[match.profile],
            status: match.status
        }));
    }
);

export const selectMatch = createSelector(
    selectMatchesWithProfiles,
    (state: RootState, matchId: number) => matchId,
    (matches, matchId) => matches.find((match) => match.id === matchId)
);

export const selectMatchByProfile = createSelector(
    selectMatchesWithProfiles,
    (state: RootState, profileId: number) => profileId,
    (matches, profileId) => matches.find((match) => match.profile.id === profileId)
);
