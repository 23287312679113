import { Box, ImageList, ImageListItem } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { motion } from 'framer-motion';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Area } from 'react-easy-crop/types';
import { useDispatch } from 'react-redux';
import { theme } from '../../theme';
import { ImageInput } from '../../components/fileInput';
import { MediaCard, MediaCardWithAspectRatio } from '../../components/media';
import { Album, createAlbumImage, deleteImage, Media, updateAlbumImageOrder } from '../../entities/album';
import { LinkedList2D } from '../../utils/linkedList2D';

interface MediaCardDeletableProps {
    media: Media;
}

function MediaCardDeletable(props: MediaCardDeletableProps) {
    const dispatch = useDispatch();
    function deleteMedia(): void {
        dispatch(deleteImage(props.media.id));
    }
    return (
        <>
            <MediaCard media={props.media} />
            <Box
                onTouchEnd={() => deleteMedia()}
                style={{
                    position: 'absolute',
                    bottom: '1vw',
                    right: '1.5vw',
                    zIndex: 1
                }}
            >
                <Remove
                    color="secondary"
                    style={{
                        backgroundColor: theme.palette.common.white,
                        borderRadius: '50%',
                        boxShadow: '0 0 2px rgba(0,0,0,0.2)',
                        fontSize: '3vh'
                    }}
                />
            </Box>
        </>
    );
}

interface MediaCardAddProps {
    album: Album;
}

function MediaCardAdd(props: MediaCardAddProps) {
    const dispatch = useDispatch();
    function uploadImageToAlbum(file: File, croppedArea: Area): void {
        dispatch(
            createAlbumImage({
                album: props.album,
                croppedArea,
                file
            })
        );
    }
    return (
        <ImageInput onComplete={(file, cropped) => uploadImageToAlbum(file, cropped)}>
            <MediaCardWithAspectRatio>
                <Box
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%'
                    }}
                >
                    <Add
                        color="secondary"
                        style={{
                            backgroundColor: theme.palette.common.white,
                            borderRadius: '50%',
                            boxShadow: '0 0 2px rgba(0,0,0,0.2)',
                            fontSize: '5vh'
                        }}
                    />
                </Box>
            </MediaCardWithAspectRatio>
        </ImageInput>
    );
}

interface MediaAlbumProps {
    album: Album;
}

const DraggableMediaContext = createContext<{
    sortedMedia: Media[];
    setSortedMedia: React.Dispatch<React.SetStateAction<Media[]>>;
    sortedMediaLinkedList: LinkedList2D<Media> | null;
    setSortedMediaLinkedList: React.Dispatch<React.SetStateAction<LinkedList2D<Media> | null>>;
    save: () => void;
} | null>(null);

function DraggableAlbumMediaListItem(props: { media: Media }) {
    const draggableMediaContext = useContext(DraggableMediaContext);
    const refListItem = useRef<HTMLLIElement>(null);
    const refDrag = useRef<HTMLDivElement>(null);
    const motionDragTransition = {
        min: 0,
        max: 100,
        bounceDamping: 18,
        bounceStiffness: 200
    };
    const motionTransition = {
        type: 'spring',
        damping: 30,
        stiffness: 500
    };
    const motionVariants = {
        init: { scale: 0 },
        idle: {
            scale: 1,
            zIndex: 10,
            transitionEnd: {
                zIndex: 0
            }
        },
        tap: {
            scale: 1.15,
            transition: {
                type: 'spring',
                damping: 12,
                stiffness: 500
            },
            zIndex: 10
        },
        drag: { zIndex: 10 }
    };

    function onPositionChange(): void {
        if (!refListItem.current || !refDrag.current) {
            return;
        }
        const listItemBounds = refListItem.current.getBoundingClientRect();
        const dragBounds = refDrag.current.getBoundingClientRect();
        const hysteresisOffset = 10;
        const element = draggableMediaContext?.sortedMediaLinkedList?.findElement(props.media);
        if (!element || !draggableMediaContext?.sortedMediaLinkedList || !listItemBounds) {
            return;
        }
        if (dragBounds.top > listItemBounds.bottom + hysteresisOffset) {
            draggableMediaContext?.sortedMediaLinkedList.moveElementDown(element);
        }
        if (dragBounds.bottom < listItemBounds.top - hysteresisOffset) {
            draggableMediaContext?.sortedMediaLinkedList.moveElementUp(element);
        }
        if (dragBounds.left > listItemBounds.right + hysteresisOffset) {
            draggableMediaContext?.sortedMediaLinkedList.moveElementRight(element);
        }
        if (dragBounds.right < listItemBounds.left - hysteresisOffset) {
            draggableMediaContext?.sortedMediaLinkedList.moveElementLeft(element);
        }
        draggableMediaContext?.setSortedMedia(draggableMediaContext?.sortedMediaLinkedList.list);
    }

    return (
        <ImageListItem ref={refListItem}>
            <motion.div
                ref={refDrag}
                layout
                drag
                dragConstraints={{ bottom: 0, left: 0, right: 0, top: 0 }}
                dragElastic={1}
                dragTransition={motionDragTransition}
                transition={motionTransition}
                variants={motionVariants}
                animate="idle"
                initial="init"
                whileDrag="drag"
                onDrag={() => onPositionChange()}
                onDragEnd={draggableMediaContext?.save}
            >
                <MediaCardDeletable media={props.media} />
            </motion.div>
        </ImageListItem>
    );
}

export function MediaAlbum(props: MediaAlbumProps) {
    const dispatch = useDispatch();
    const { album } = props;
    const [sortedMedia, setSortedMedia] = useState<Media[]>([]);
    const [sortedMediaLinkedList, setSortedMediaLinkedList] = useState<LinkedList2D<Media> | null>(null);

    useEffect(() => {
        const linkedList = new LinkedList2D<Media>(album.media, 3);
        setSortedMedia(album.media);
        setSortedMediaLinkedList(linkedList);
    }, [album.media]);

    function save() {
        dispatch(
            updateAlbumImageOrder({
                album,
                media: sortedMedia
            })
        );
    }

    return (
        <DraggableMediaContext.Provider
            value={{
                sortedMedia,
                setSortedMedia,
                sortedMediaLinkedList,
                setSortedMediaLinkedList,
                save
            }}
        >
            <Box onContextMenu={(event) => event.preventDefault()}>
                <ImageList cols={3} gap={8} sx={{ overflow: 'hidden' }}>
                    {sortedMedia.map(
                        (media: Media): React.ReactNode => (
                            <DraggableAlbumMediaListItem key={media.id} media={media} />
                        )
                    )}
                    {sortedMedia.length < 9 && (
                        <ImageListItem key="add-media">
                            <MediaCardAdd album={album} />
                        </ImageListItem>
                    )}
                </ImageList>
            </Box>
        </DraggableMediaContext.Provider>
    );
}
