import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { PropsWithChildren, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';

interface ImageInputProps {
    onComplete: (file: File, cropped: Area) => void;
}

interface Crop {
    crop: Point;
    croppedArea?: Area;
    processedImage?: Blob;
    zoom: number;
}

export function ImageInput(props: PropsWithChildren<ImageInputProps>) {
    const aspectRatio: number = 2 / 3;
    const inputRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<{ file: File; fileUrl: string } | undefined>(undefined);
    const [crop, setCrop] = useState<Crop>({
        crop: { x: 0, y: 0 },
        zoom: 1
    });
    const [cropDialogOpen, setCropDialogOpen] = useState<boolean>(false);

    function resetFileInput(): void {
        setCropDialogOpen(false);
        if (inputRef?.current) {
            inputRef.current.value = '';
        }
    }
    function onSelectFile(files: FileList | null): void {
        if (files) {
            const file: File = files[0];
            const fileUrl = URL.createObjectURL(file);
            setFile({ file, fileUrl });
            setCropDialogOpen(true);
        }
    }

    function acceptCrop(): void {
        if (crop.croppedArea && file?.file) {
            props.onComplete(file.file, crop.croppedArea);
        }
        resetFileInput();
    }

    function rejectCrop(): void {
        resetFileInput();
    }

    return (
        <div>
            <label style={{ display: 'block' }}>
                {props.children}
                <input
                    ref={inputRef}
                    accept="image/*"
                    hidden
                    onChange={(event) => onSelectFile(event.target.files)}
                    type="file"
                />
            </label>
            <Dialog open={cropDialogOpen} fullScreen>
                <DialogContent>
                    <Box position="absolute" top="2vh" bottom="13vh" left="2vw" right="2vw">
                        <Cropper
                            aspect={aspectRatio}
                            image={file?.fileUrl}
                            crop={crop.crop}
                            objectFit="vertical-cover"
                            onCropChange={(point) => setCrop({ ...crop, crop: point })}
                            onCropComplete={(_, croppedAreaPixels) =>
                                setCrop({ ...crop, croppedArea: croppedAreaPixels })
                            }
                            onZoomChange={(zoom) => setCrop({ ...crop, zoom })}
                            zoom={crop.zoom}
                        />
                    </Box>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={() => rejectCrop()} color="warning" size="large" variant="contained">
                        Back
                    </Button>
                    <Button onClick={() => acceptCrop()} color="secondary" size="large" variant="contained">
                        Send It
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
