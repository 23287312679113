import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { retrieveMatches, updateLatestActivity } from './likes';
import { logoutUser, UserRetrieveData } from './user';
import { websocket } from '../providers';
import { store } from './store';

export interface Message {
    author: number;
    created: string;
    id: number;
    message: string;
    group: number;
}

export interface MessageWS {
    author: UserRetrieveData;
    created: string;
    group: number;
    id: number;
    text: string;
}

export interface MessageState {
    byChat: Record<number, Message[]>;
}

export interface SendMessage {
    message: string;
    group: number;
}

const initialState: MessageState = {
    byChat: {}
};

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        addMessage: (state: Draft<MessageState>, action: PayloadAction<MessageWS>) => {
            const message = action.payload;
            if (!state.byChat[message.group]?.length) {
                state.byChat[message.group] = [];
            }
            state.byChat[message.group].push({
                author: message.author.id,
                created: message.created,
                id: message.id,
                group: message.group,
                message: message.text
            });
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(logoutUser.fulfilled, (state) => {
                Object.assign(state, initialState);
            })
            .addCase(retrieveMatches.fulfilled, (state, action) => {
                action.payload.forEach((rawMatch) => {
                    state.byChat[rawMatch.chat_group.id] = rawMatch.chat_group.posts.map((rawMessage) => ({
                        author: rawMessage.author.id,
                        created: rawMessage.created,
                        id: rawMessage.id,
                        group: rawMessage.group,
                        message: rawMessage.text
                    }));
                });
            });
    }
});

export const messageReducer = messageSlice.reducer;
export const addMessage = messageSlice.actions.addMessage;

websocket.addEventListener('chat', (message: MessageWS) => {
    store.dispatch(addMessage(message));
    store.dispatch(updateLatestActivity({ chat: message.group, latestActivity: message.created }));
});
