import { AnyAction, AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import http, { ResponseError } from '../utils/http';
import { RootState } from './store';

export interface ProfileQuestionResponse {
    content?: string;
    lastSavedContent?: string;
    options: number[];
    id?: number;
    profile: number;
    question: number;
}
export interface ProfileQuestionEdit {
    content?: string;
    lastSavedContent?: string;
    options?: number[];
    id?: number;
    profile: number;
    question: number;
}
type ProfileQuestionResponseRaw = ProfileQuestionResponse;

type ProfileQuestionResponseCreateData = {
    question: number;
    content?: string;
    options?: number[];
};

type ProfileQuestionResponseUpdateData = {
    question: number;
    content?: string;
    options?: number[];
};

export interface ProfileQuestionResponseState {
    error?: ResponseError;
    byProfile: {
        [profile: number]: {
            [question: number]: ProfileQuestionResponse;
        };
    };
    requestStatus: 'init' | 'pending' | 'complete' | 'error';
}

const initialState: ProfileQuestionResponseState = {
    byProfile: {},
    requestStatus: 'init'
};

export const saveQuestionResponse = createAsyncThunk<
    ProfileQuestionResponseRaw,
    { type: 'content'; question: number; profile: number } | { type: 'response'; response: ProfileQuestionResponse },
    { rejectValue: ResponseError; state: RootState }
>('profileQuestionResponse/update', async (values, thunkApi) => {
    try {
        if (values.type === 'content') {
            const { profile, question } = values;
            const state = thunkApi.getState();
            const response = state.profileQuestionResponse.byProfile[profile][question];
            if (!response?.id) {
                return await http.create<ProfileQuestionResponseRaw, ProfileQuestionResponseCreateData>(
                    '/profile/response/create/',
                    { data: { content: response.content!, question: response.question } }
                );
            }
            if (response.content !== response.lastSavedContent) {
                return await http.update<ProfileQuestionResponseRaw, ProfileQuestionResponseUpdateData>(
                    '/profile/response/update/:id/',
                    {
                        data: { content: response.content!, question },
                        urlParams: { id: response.id }
                    }
                );
            }
            return response;
        }
        const { response } = values;
        if (!response.id) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...data } = response;
            return await http.create<ProfileQuestionResponseRaw, ProfileQuestionResponseCreateData>(
                '/profile/response/create/',
                { data }
            );
        }
        return await http.update<ProfileQuestionResponseRaw, ProfileQuestionResponseUpdateData>(
            '/profile/response/update/:id/',
            {
                data: response,
                urlParams: { id: response.id }
            },
            'PUT'
        );
    } catch (error) {
        return thunkApi.rejectWithValue(error as ResponseError);
    }
});

export const retrieveProfileQuestionResponses = createAsyncThunk<
    ProfileQuestionResponseRaw[],
    number,
    { rejectValue: ResponseError }
>('profileQuestionResponse/retrieve/list', async (profile, thunkApi) => {
    try {
        return await http.retrieve<ProfileQuestionResponseRaw[]>('/profile/response/list/:profile/', {
            urlParams: { profile }
        });
    } catch (error) {
        return thunkApi.rejectWithValue(error as ResponseError);
    }
});

type GenericAsyncThunk = AsyncThunk<unknown, unknown, never>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

type ProfileQuestionResponseSaveAsyncThunk = AsyncThunk<ProfileQuestionResponseRaw, unknown, never>;
type ProfileQuestionResponseSaveFulfilledAction = ReturnType<ProfileQuestionResponseSaveAsyncThunk['fulfilled']>;

const profileQuestionResponseSaveFulfilledAction = (
    action: AnyAction
): action is ProfileQuestionResponseSaveFulfilledAction => {
    return (
        action.type.startsWith('profileQuestionResponse') &&
        (action.type.includes('/create') || action.type.includes('/update')) &&
        action.type.endsWith('/fulfilled')
    );
};
const profileQuestionResponsePendingAction = (action: AnyAction): action is PendingAction => {
    return action.type.startsWith('profileQuestionResponse/') && action.type.endsWith('/pending');
};
const profileQuestionResponseRejectedAction = (action: AnyAction): action is RejectedAction => {
    return action.type.startsWith('profileQuestionResponse/') && action.type.endsWith('/rejected');
};

export const profileQuestionResponseSlice = createSlice({
    name: 'profileQuestionResponse',
    initialState,
    reducers: {
        edit: (state, action: PayloadAction<{ response: ProfileQuestionEdit; content?: string }>) => {
            const { response, content = '' } = action.payload;
            if (!state.byProfile?.[response.profile]) {
                state.byProfile[response.profile] = {};
            }
            state.byProfile[response.profile][response.question] = {
                ...(state.byProfile?.[response.profile]?.[response.question] ?? response),
                content
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(retrieveProfileQuestionResponses.fulfilled, (state, action) => {
            const profileQuestionResponses: ProfileQuestionResponse[] = action.payload.map((response) => ({
                ...response,
                lastSavedContent: response.content
            }));
            const profile: number = action.meta.arg;
            state.byProfile[profile] = {
                ...profileQuestionResponses.reduce(
                    (state, response) => ({
                        ...state,
                        [response.question]: response
                    }),
                    {}
                )
            };
            state.requestStatus = 'complete';
        });
        builder.addMatcher(profileQuestionResponseSaveFulfilledAction, (state, action) => {
            const profileQuestionResponse: ProfileQuestionResponse = action.payload;
            state.byProfile[profileQuestionResponse.profile][profileQuestionResponse.question] =
                profileQuestionResponse;
            state.requestStatus = 'complete';
        });
        builder.addMatcher(profileQuestionResponsePendingAction, (state) => {
            state.requestStatus = 'pending';
        });
        builder.addMatcher(profileQuestionResponseRejectedAction, (state, action) => {
            state.error = action.payload;
            state.requestStatus = 'error';
        });
    }
});

export const profileQuestionResponseReducer = profileQuestionResponseSlice.reducer;
export const editProfileQuestionResponse = profileQuestionResponseSlice.actions.edit;
