import React, { useContext, useState } from 'react';
import { LikeContext } from '../likes/likeContext';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { blockProfile } from '../entities/profile';
import {
    Avatar as MuiAvatar,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Typography
} from '@mui/material';
import { Close, GppMaybe } from '@mui/icons-material';

interface BlockProfileProps {
    button?: boolean;
    size?: 'small' | 'large';
    onBlock?: () => void;
}

export function BlockProfile(props: BlockProfileProps) {
    const likeContext = useContext(LikeContext);
    const dispatch = useDispatch();
    const { t: translate } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const { size } = props;
    async function dispatchBlockProfile() {
        if (likeContext?.profile?.id) {
            dispatch(blockProfile({ profile: likeContext.profile.id }));
            props?.onBlock?.();
        }
    }
    return (
        <>
            {!props?.button && (
                <Paper
                    elevation={1}
                    sx={{ padding: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => setShowModal(true)}
                >
                    <Typography sx={{ flexGrow: 1 }} color="warning" variant="body1" textAlign="center" gutterBottom>
                        {translate('block.text', { appName: process.env.REACT_APP_META_NAME })}
                    </Typography>
                    <Box sx={{ flexShrink: 1 }}>
                        <MuiAvatar sx={(theme) => ({ backgroundColor: theme.palette.warning.main })}>
                            <GppMaybe />
                        </MuiAvatar>
                    </Box>
                </Paper>
            )}
            {props?.button && (
                <Box onClick={() => setShowModal(true)}>
                    <MuiAvatar
                        sx={(theme) => ({
                            backgroundColor: theme.likeControl.block.backgroundColor,
                            border: `0.15em solid ${theme.likeControl.block.borderColor}`,
                            boxShadow: `0px 0px 4px ${theme.likeControl.block.shadowColor}`,
                            padding: size === 'large' ? '1.2em' : '0.5em'
                        })}
                    >
                        <GppMaybe
                            sx={(theme) => ({
                                fontSize: size === 'large' ? '1.5em' : '1.25em',
                                color: theme.likeControl.block.color
                            })}
                        />
                    </MuiAvatar>
                </Box>
            )}
            <Dialog open={showModal} onClose={() => setShowModal(false)}>
                <DialogTitle sx={{ margin: 0, padding: 2, paddingBottom: 0 }} color="secondary">
                    {translate('block.dialog.title')}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setShowModal(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <Close />
                </IconButton>
                <DialogContent>
                    <Typography variant="body1" gutterBottom>
                        {translate('block.dialog.body', { username: likeContext?.profile?.username })}
                    </Typography>
                    <Button
                        color="warning"
                        variant="contained"
                        fullWidth
                        onClick={() => dispatchBlockProfile()}
                        sx={{ marginTop: 2, marginBottom: 1 }}
                    >
                        {translate('block.dialog.button')}
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
}
