import './index.css';
import { createRoot } from 'react-dom/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { store } from './entities/store';
import App from './App';
import React from 'react';
import { theme } from './theme';
import { AuthProvider } from './auth/auth';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <CssBaseline />
        <Provider store={store}>
            <AuthProvider>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </AuthProvider>
        </Provider>
    </React.StrictMode>
);

serviceWorkerRegistration.register();
