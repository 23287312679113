import { createSelector } from '@reduxjs/toolkit';
import { Message } from '../entities/message';
import { MatchWithProfile, selectMatchesWithProfiles } from '../entities/selectors/likes';
import { RootState } from '../entities/store';

const messagesByChatSelector = (state: RootState): Record<number, Message[]> => state.message.byChat;

export const sortedMessagesByChatSelector = createSelector(
    messagesByChatSelector,
    (messagesByChat: Record<number, Message[]>): Record<number, Message[]> => {
        return Object.keys(messagesByChat).reduce(
            (state: Record<number, Message[]>, chat: string): Record<number, Message[]> => {
                const chatId = parseInt(chat, 10);
                const messages = [...(messagesByChat[chatId] ?? [])];
                messages.sort();
                state[chatId] = messages;
                return state;
            },
            {}
        );
    }
);
export const messagesSelector = createSelector(
    sortedMessagesByChatSelector,
    (state: RootState, props: { chatId: number }) => props.chatId,
    (messagesByChatId, chatId) => messagesByChatId[chatId] ?? []
);

export const lastMessageByChatSelector = createSelector(
    selectMatchesWithProfiles,
    sortedMessagesByChatSelector,
    (matches: MatchWithProfile[], messagesByChat: Record<number, Message[]>): Record<number, Message> =>
        matches.reduce((record: Record<number, Message>, match: MatchWithProfile): Record<number, Message> => {
            if (match?.chat) {
                const messages = messagesByChat[match.chat] ?? [];
                record[match.chat] = messages[messages.length - 1];
            }
            return record;
        }, {})
);
