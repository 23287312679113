import { LocationInput } from './components/locationInput';
import { Box, Button } from '@mui/material';
import React from 'react';
import { useAuth } from '../auth/auth';
import { useSelector } from 'react-redux';
import { RootState } from '../entities/store';
import { DistanceSlider } from './components/distanceSlider';
import { ChangePassword } from './components/changePassword';
import { Notifications } from './components/notifications';

export function SettingsScreen() {
    const auth = useAuth();
    const profile = useSelector((state: RootState) => state.profile.userProfile);
    if (!profile) {
        return <></>;
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 1
            }}
        >
            <LocationInput profile={profile} gutterBottom />
            <DistanceSlider profile={profile} gutterBottom />
            <Notifications gutterBottom />
            <ChangePassword gutterBottom />
            <Box sx={{ paddingTop: 4, paddingBottom: 4 }}>
                <Button variant="contained" color="secondary" onClick={() => auth.logout()}>
                    Signout
                </Button>
            </Box>
        </Box>
    );
}
