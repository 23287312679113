import React from 'react';
import { Avatar as AvatarMui } from '@mui/material';
import { AvatarProps } from '@mui/material/Avatar/Avatar';
import { theme } from '../theme';
import defaultAvatar from '../assets/default-avatar.png';

export function Avatar(props: AvatarProps) {
    const { src = defaultAvatar, style, ...avatarProps } = props;
    return (
        <AvatarMui
            src={src}
            {...avatarProps}
            style={{
                backgroundColor: theme.palette.secondary.contrastText,
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: theme.palette.secondary.main,
                ...style
            }}
        />
    );
}
