import { Button, CardContent, CardHeader, Input, InputLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../theme';
import { AuthContainer } from './container';
import { useAuth } from './auth';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../entities/store';
import { PeakyPassword } from '../components/password';

export function LoginScreen() {
    const { t } = useTranslation();
    const auth = useAuth();
    const navigate = useNavigate();
    const error = useSelector((state: RootState) => state.user.error);
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');

    function isLoginEnabled() {
        return username.length > 0 && password.length > 0;
    }

    useEffect(() => {
        if (auth.user.status === 'loggedIn') {
            navigate('/swipe', { replace: true });
        }
    }, [auth.user.status]);

    return (
        <AuthContainer logo>
            <CardHeader title={t('login.card.title')} style={{ color: theme.palette.secondary.main }} />
            <CardContent>
                <Typography color="primary">{t('login.card.text')}</Typography>
                <Input onChange={(event) => setUsername(event.target.value)} fullWidth value={username} />
                <InputLabel style={{ color: theme.palette.primary.main }}>username</InputLabel>
                <PeakyPassword
                    onChange={(event) => setPassword(event.target.value)}
                    fullWidth
                    type="password"
                    value={password}
                    variant="standard"
                />
                <InputLabel style={{ color: theme.palette.primary.main }}>password</InputLabel>
                {error?.data?.password && (
                    <Typography color="error" variant="subtitle2">
                        {error.data.password}
                    </Typography>
                )}
            </CardContent>
            <CardContent>
                <Button
                    color="secondary"
                    fullWidth
                    onClick={() => auth.login({ username, password })}
                    size="medium"
                    variant="contained"
                    disabled={!isLoginEnabled()}
                >
                    {t('login.card.button.login')}
                </Button>
                {error?.data?.non_field_errors && (
                    <Typography color="error" variant="subtitle2" textAlign="center" sx={{ padding: 1 }}>
                        {error.data.non_field_errors}
                    </Typography>
                )}
                <Typography align="center" color="primary" style={{ marginTop: 20 }}>
                    Don&lsquo;t have an account?
                </Typography>
                <Button color="secondary" href="/signup" fullWidth size="medium" variant="outlined">
                    {t('login.card.button.signup')}
                </Button>
            </CardContent>
        </AuthContainer>
    );
}
