import React from 'react';
import {
    FilledInput,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    OutlinedInputProps
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export function PeakyPassword(
    props: OutlinedInputProps & { label?: string; variant?: 'standard' | 'outlined' | 'filled' }
): React.ReactElement {
    const { fullWidth, variant = 'standard', ...inputProps } = props;
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    let InputComponent = Input;
    if (variant === 'outlined') {
        InputComponent = OutlinedInput;
    } else if (variant === 'filled') {
        InputComponent = FilledInput;
    }

    return (
        <FormControl variant={variant} fullWidth={fullWidth} sx={{ marginTop: 1, marginBottom: 1 }}>
            {inputProps?.label && <InputLabel>{inputProps.label}</InputLabel>}
            <InputComponent
                {...inputProps}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
}
