import Cookie from 'cookie';
import { camelToSnakeString, keyTransform } from './object';

interface UrlParams {
    [key: string]: number | string;
}
interface QueryParams {
    [key: string]: number | string | Array<number | string>;
}
interface Options {
    urlParams?: UrlParams;
    queryParams?: QueryParams;
}
interface OptionsWithData<T = void> extends Options {
    data: T;
}
interface OptionsWithPartialData<T> extends OptionsWithData<T> {
    partial?: boolean;
}

export interface ResponseError {
    data?: unknown;
    type: 'BAD_DATA' | 'NOT_AUTHENTICATED' | 'NOT_AUTHORIZED' | 'NOT_FOUND' | 'SERVER_ERROR' | 'UNKNOWN_ERROR';
}

class http {
    private baseUrl: string = (() => {
        let baseUrl =
            process.env['REACT_APP_API_URL'] ?? `${window.location.protocol}//${window.location.hostname}:8000`;
        if (baseUrl.endsWith('/')) {
            baseUrl = baseUrl.substring(0, baseUrl.length - 1);
        }
        return baseUrl;
    })();
    private defaultOptions: Partial<RequestInit> = {
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        mode: 'cors'
    };
    private getFormattedUrl(url: string, urlParams?: UrlParams, queryParams?: QueryParams): string {
        /**
         * url is in the form `/path/to/api/:id/:anotherParam/`
         * urlParams must have keys specified in url eg. `id` and `anotherParam`
         */
        if (urlParams) {
            const keys = Object.keys(urlParams);
            keys.forEach((key: string) => {
                url = url.replace(`:${key}`, encodeURIComponent(urlParams[key]));
            });
        }
        if (!url.startsWith('/')) {
            url = `/${url}`;
        }
        return `${this.baseUrl}${this.getQueryUrl(url, queryParams)}`;
    }
    private getQueryUrl(url: string, queryParams?: QueryParams): string {
        // No query parameters provided.
        if (!queryParams || Object.keys(queryParams).length === 0) {
            return url;
        }
        const parts: string[] = Object.keys(queryParams).map((key: string) => {
            const param = queryParams[key];
            let value = param;
            if (param instanceof Array && param.length > 0) {
                const values = (param as Array<number | string>).reduce(
                    (previousValues: Array<number | string>, currentValue: string | number) => {
                        const newValues = [...previousValues];
                        newValues.push(currentValue);
                        return newValues;
                    },
                    [] as Array<string | number>
                );
                value = values.join(',');
            }
            value = String(value);
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        });
        return `${url}?${parts.join('&')}`;
    }
    private async _fetch<ResponseData>(url: string, options?: Partial<RequestInit>): Promise<ResponseData> {
        try {
            const cookies = Cookie.parse(document.cookie);
            const csrftoken = cookies?.csrftoken;
            let requestOptions: RequestInit = {
                ...this.defaultOptions,
                ...options
            };
            if (csrftoken) {
                requestOptions = {
                    ...requestOptions,
                    headers: {
                        ...(requestOptions?.headers ?? {}),
                        'X-CSRFTOKEN': csrftoken
                    }
                };
            }
            const response = await fetch(url, requestOptions);
            if (response.ok) {
                if (response.status === 204) {
                    return Promise.resolve() as unknown as Promise<ResponseData>;
                }
                if (response.status >= 200 && response.status < 300) {
                    return Promise.resolve(response.json());
                }
            } else {
                if (response.status === 400) {
                    return Promise.reject({
                        data: await response.json(),
                        type: 'BAD_DATA'
                    });
                }
                if (response.status === 401) {
                    return Promise.reject({
                        type: 'NOT_AUTHORIZED'
                    });
                }
                if (response.status === 403) {
                    return Promise.reject({
                        type: 'NOT_AUTHENTICATED'
                    });
                }
                if (response.status >= 500 && response.status < 600) {
                    return Promise.reject({
                        type: 'SERVER_ERROR'
                    });
                }
            }
            return Promise.reject({ type: 'UNKNOWN_ERROR' } as ResponseError);
        } catch (e) {
            return Promise.reject({ type: 'UNKNOWN_ERROR' } as ResponseError);
        }
    }
    public async retrieve<ResponseData>(url: string, options: Options = {}) {
        const formattedUrl: string = this.getFormattedUrl(url, options.urlParams, options.queryParams);
        const fetchOptions: Partial<RequestInit> = {
            method: 'GET'
        };
        return (await this._fetch<ResponseData>(formattedUrl, fetchOptions)) as ResponseData;
    }
    public async create<ResponseData, OptionsData>(
        url: string,
        options?: OptionsWithData<OptionsData>
    ): Promise<ResponseData> {
        const formattedUrl: string = this.getFormattedUrl(url, options?.urlParams, options?.queryParams);
        const fetchOptions: Partial<RequestInit> = {
            body: options?.data ? JSON.stringify(keyTransform(options.data, camelToSnakeString)) : undefined,
            method: 'POST'
        };
        return (await this._fetch<ResponseData>(formattedUrl, fetchOptions)) as ResponseData;
    }
    public async createFile<ResponseData>(url: string, data: FormData, options?: Options) {
        const formattedUrl: string = this.getFormattedUrl(url, options?.urlParams, options?.queryParams);
        const fetchOptions: Partial<RequestInit> = {
            body: data,
            headers: { Accept: 'application/json' },
            method: 'POST'
        };
        return (await this._fetch<ResponseData>(formattedUrl, fetchOptions)) as ResponseData;
    }
    public async update<ResponseData, OptionsData>(
        url: string,
        options: OptionsWithPartialData<OptionsData>,
        method: 'PUT' | 'PATCH' = 'PUT'
    ) {
        const formattedUrl: string = this.getFormattedUrl(url, options.urlParams, options.queryParams);
        const fetchOptions: Partial<RequestInit> = {
            ...this.defaultOptions,
            body: options?.data ? JSON.stringify(keyTransform(options.data, camelToSnakeString)) : undefined,
            method: method
        };
        return (await this._fetch<ResponseData>(formattedUrl, fetchOptions)) as ResponseData;
    }
    public async delete(url: string, options: Options) {
        const formattedUrl: string = this.getFormattedUrl(url, options.urlParams, options.queryParams);
        const fetchOptions: Partial<RequestInit> = {
            ...this.defaultOptions,
            method: 'DELETE'
        };
        return (await this._fetch<void>(formattedUrl, fetchOptions)) as void;
    }
}

export default new http();
