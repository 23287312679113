import { AuthContainer } from './container';
import { Button, CardContent, CardHeader, Typography } from '@mui/material';
import { theme } from '../theme';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function ReferralInfo() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <AuthContainer logo>
            <CardHeader title={t('referralInfo.card.title')} style={{ color: theme.palette.secondary.main }} />
            <CardContent>
                <Typography color="primary">
                    <Trans i18nKey="referralInfo.card.text" />
                </Typography>
            </CardContent>
            <CardContent>
                <Button fullWidth size="medium" variant="contained" color="secondary" onClick={() => navigate(-1)}>
                    Back
                </Button>
            </CardContent>
        </AuthContainer>
    );
}
