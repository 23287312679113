import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http, { ResponseError } from '../utils/http';
import { RootState } from './store';

export type ProfileQuestionOption = {
    id: number;
    name: string;
};

export interface ProfileQuestion {
    alternateSortOrder?: number;
    description: string;
    icon?: string;
    id: number;
    name: string;
    sortOrder: number;
    widgetType: string;
    widgetOptions: Record<string, string | number>;
    options: ProfileQuestionOption[];
}

interface ProfileQuestionRaw {
    alternate_sort_order: number | null;
    description: string;
    icon: string | null;
    id: number;
    name: string;
    sort_order: number;
    widget_type: string;
    widget_options: Record<string, string | number>;
    options: Array<Record<string, string | number>>;
}

export interface ProfileQuestionState {
    byId: { [question: number]: ProfileQuestion };
    error?: ResponseError;
    questions: ProfileQuestion[];
    requestStatus: 'init' | 'pending' | 'complete' | 'error';
}

const initialState: ProfileQuestionState = {
    byId: {},
    questions: [],
    requestStatus: 'init'
};

export const retrieveUserProfileQuestions = createAsyncThunk<
    ProfileQuestionRaw[],
    void,
    { rejectValue: ResponseError | { type: 'ALREADY_RETRIEVED' }; state: RootState }
>('profileQuestion/retrieve/user/list', async (type, thunkApi) => {
    const state = thunkApi.getState();
    if (state.profileQuestion.questions.length) {
        return Promise.reject({ type: 'ALREADY_RETRIEVED' });
    }
    try {
        return await http.retrieve<ProfileQuestionRaw[]>('/profile/question/user/list/');
    } catch (error) {
        return thunkApi.rejectWithValue(error as ResponseError);
    }
});

export const profileQuestionSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(retrieveUserProfileQuestions.fulfilled, (state, action) => {
            const rawProfileQuestions: ProfileQuestionRaw[] = action.payload;
            const profileQuestions: ProfileQuestion[] = [];
            rawProfileQuestions.forEach((rawProfileQuestion) => {
                const profileQuestion = {
                    alternateSortOrder: rawProfileQuestion.alternate_sort_order ?? undefined,
                    description: rawProfileQuestion.description,
                    icon: rawProfileQuestion.icon ?? undefined,
                    id: rawProfileQuestion.id,
                    name: rawProfileQuestion.name,
                    sortOrder: rawProfileQuestion.sort_order,
                    widgetType: rawProfileQuestion.widget_type,
                    widgetOptions: rawProfileQuestion.widget_options,
                    options: rawProfileQuestion.options
                };
                state.byId[profileQuestion.id] = profileQuestion;
                profileQuestions.push(profileQuestion);
            });
            state.questions = profileQuestions.sort((a, b) => a.sortOrder - b.sortOrder);
            state.requestStatus = 'complete';
        });
        builder.addCase(retrieveUserProfileQuestions.pending, (state) => {
            state.requestStatus = 'pending';
        });
        builder.addCase(retrieveUserProfileQuestions.rejected, (state, action) => {
            if (action.payload?.type === 'ALREADY_RETRIEVED') {
                state.requestStatus = 'complete';
            } else {
                state.error = action.payload;
                state.requestStatus = 'error';
            }
        });
    }
});

export const profileQuestionReducer = profileQuestionSlice.reducer;
