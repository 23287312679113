import React, { createContext, ReactNode } from 'react';
import { Profile, removeSwipedProfile } from '../entities/profile';
import { createLike, createPass } from '../entities/likes';
import { useDispatch } from 'react-redux';

export const LikeContext = createContext<{
    profile: Profile | undefined;
    like: () => void;
    pass: () => void;
} | null>(null);

export function LikeProvider(props: { children: ReactNode; profile: Profile }) {
    const dispatch = useDispatch();

    function like() {
        dispatch(removeSwipedProfile());
        dispatch(createLike(props.profile));
    }

    function pass() {
        dispatch(removeSwipedProfile());
        dispatch(createPass(props.profile));
    }

    return (
        <LikeContext.Provider
            value={{
                profile: props.profile,
                like,
                pass
            }}
        >
            {props.children}
        </LikeContext.Provider>
    );
}
