import React, { useRef, useState } from 'react';
import { Paper, Slider, Typography } from '@mui/material';
import { Profile, updateUserProfile } from '../../entities/profile';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

function getAriaText(value: number): string {
    return `${value} km`;
}
const PrettySlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.secondary.main,
    height: 8,
    '& .MuiSlider-track': {
        border: 'none'
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit'
        },
        '&:before': {
            display: 'none'
        }
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: theme.palette.secondary.main,
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
        },
        '& > *': {
            transform: 'rotate(45deg)'
        }
    }
}));

export function DistanceSlider(props: { profile: Profile; gutterBottom?: boolean }) {
    const dispatch = useDispatch();
    const [distance, setDistance] = useState((props.profile.filters?.distance ?? 0) / 1000);
    const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    if (!props.profile) {
        return <></>;
    }
    return (
        <Paper sx={{ padding: 2, width: '100%', margin: props?.gutterBottom ? 1 : 0 }}>
            <Typography variant="body2" color="secondary" sx={{ marginBottom: 2 }}>
                Maximum Distance ({distance} km)
            </Typography>
            <PrettySlider
                color="secondary"
                aria-label="Distance"
                defaultValue={distance ?? null}
                getAriaValueText={getAriaText}
                valueLabelDisplay="auto"
                step={10}
                marks
                min={0}
                max={300}
                onChange={(event, value) => {
                    setDistance(value as number);
                    if (timeout.current !== null) {
                        clearTimeout(timeout.current);
                        timeout.current = null;
                    }
                    timeout.current = setTimeout(
                        () =>
                            dispatch(
                                updateUserProfile({
                                    id: props.profile.id,
                                    filters: { distance: (value as number) * 1000 }
                                })
                            ),
                        500
                    );
                }}
            />
        </Paper>
    );
}
