import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import http, { ResponseError } from '../../utils/http';
import { camelToSnakeString, keyTransform, snakeToCamelString } from '../../utils/object';
import { PeakyPassword } from '../../components/password';

type ChangePasswordRequestData = {
    oldPassword: string;
    newPassword1: string;
    newPassword2: string;
};

type ChangePasswordRawRequestData = {
    old_password: string;
    new_password1: string;
    new_password2: string;
};

type ChangePasswordError = ResponseError & {
    data: {
        oldPassword?: string[];
        newPassword1?: string[];
        newPassword2?: string[];
    };
};

async function changePassword(
    data: ChangePasswordRequestData,
    successCallback: () => void,
    errorCallback: (error: ChangePasswordError) => void
) {
    try {
        await http.create<never, ChangePasswordRawRequestData>(
            '/auth/password/change/',
            keyTransform({ data }, camelToSnakeString)
        );
        successCallback();
    } catch (error) {
        errorCallback(keyTransform(error, snakeToCamelString) as ChangePasswordError);
    }
}

export function ChangePassword(props: { gutterBottom?: boolean }) {
    const [showModal, setShowModal] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [error, setError] = useState<ChangePasswordError | undefined>();
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    function closeModal() {
        setOldPassword('');
        setNewPassword1('');
        setNewPassword2('');
        setError(undefined);
        setShowModal(false);
        setShowSuccessModal(false);
    }
    return (
        <Paper sx={{ width: '100%', margin: props?.gutterBottom ? 1 : 0 }}>
            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" padding={2}>
                <Typography color="secondary">Change Password</Typography>
                <Button variant="contained" color="info" onClick={() => setShowModal(true)}>
                    Edit
                </Button>
            </Stack>
            <Dialog fullWidth open={showModal} onClose={() => closeModal()}>
                <DialogTitle sx={{ margin: 0, padding: 2, paddingBottom: 0 }} color="secondary">
                    Change Password
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => closeModal()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <Close />
                </IconButton>
                <DialogContent>
                    <PeakyPassword
                        value={oldPassword}
                        onChange={(event) => setOldPassword(event.target.value)}
                        type="password"
                        label="Current Password"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                    />
                    {error?.data.oldPassword?.map((errorString) => (
                        <Typography color="error" variant="subtitle2" key={errorString}>
                            {errorString}
                        </Typography>
                    ))}
                    <PeakyPassword
                        value={newPassword1}
                        onChange={(event) => setNewPassword1(event.target.value)}
                        type="password"
                        label="New Password"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                    />
                    {error?.data.newPassword1?.map((errorString) => (
                        <Typography color="error" variant="subtitle2" key={errorString}>
                            {errorString}
                        </Typography>
                    ))}
                    <PeakyPassword
                        value={newPassword2}
                        onChange={(event) => setNewPassword2(event.target.value)}
                        type="password"
                        label="Confirm Password"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                    />
                    {error?.data.newPassword2?.map((errorString) => (
                        <Typography color="error" variant="subtitle2" key={errorString}>
                            {errorString}
                        </Typography>
                    ))}
                    <Box
                        sx={{
                            display: 'flex',
                            direction: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 2
                        }}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                changePassword(
                                    { oldPassword, newPassword1, newPassword2 },
                                    () => {
                                        setShowModal(false);
                                        setShowSuccessModal(true);
                                    },
                                    setError
                                );
                            }}
                        >
                            Confirm
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog fullWidth open={showSuccessModal} onClose={closeModal}>
                <DialogTitle sx={{ margin: 0, padding: 2, paddingBottom: 0 }} color="secondary">
                    Password Changed
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <Close />
                </IconButton>
                <DialogContent>
                    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" padding={2}>
                        <Typography color="primary">Your password was changed!</Typography>
                        <Button size="large" variant="outlined" color="secondary" onClick={closeModal}>
                            Yay 🍑
                        </Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </Paper>
    );
}
