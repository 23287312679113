export function keyTransform<ObjectType, ReturnType>(
    inputObject: ObjectType,
    transformer: (inString: string) => string
): ReturnType {
    const output: Record<string, unknown> = {};
    for (const key in inputObject) {
        if (!key) {
            continue;
        }
        const newKey = transformer(key);
        const val = inputObject[key];
        output[newKey] =
            typeof val === 'object' && val !== null && !(val instanceof Array) ? keyTransform(val, transformer) : val;
    }
    return output as ReturnType;
}

export function camelToSnakeString(inString: string): string {
    const uppercases = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return inString
        .split('')
        .map((char: string): string => {
            return uppercases.includes(char) ? `_${char.toLowerCase()}` : char;
        })
        .join('');
}

export function snakeToCamelString(inString: string): string {
    let stringIndex = 0;
    let outString = '';
    while (stringIndex < inString.length) {
        if (inString[stringIndex] === '_' && inString?.[stringIndex + 1] && inString[stringIndex + 1] !== '_') {
            outString = outString.concat(inString.charAt(stringIndex + 1).toUpperCase());
            stringIndex++;
        } else {
            outString = outString.concat(inString.charAt(stringIndex));
        }
        stringIndex++;
    }
    return outString;
}
