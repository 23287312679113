import * as React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import customTheme from './theme.json';

declare module '@mui/material/styles' {
    interface Theme {
        profileFlare?: {
            background: string;
            color: string;
            icon: string;
        };
        likeControl: {
            pass: {
                color: string;
                backgroundColor: string;
                borderColor: string;
                shadowColor: string;
            };
            like: {
                color: string;
                backgroundColor: string;
                borderColor: string;
                shadowColor: string;
            };
            block: {
                color: string;
                backgroundColor: string;
                borderColor: string;
                shadowColor: string;
            };
            refresh: {
                color: string;
                backgroundColor: string;
                backgroundColorDark?: string;
                borderColor: string;
                shadowColor: string;
            };
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        profileFlare?: {
            color: string;
            icon: string;
        };
    }
}

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
    function LinkBehavior(props, ref) {
        const { href, ...other } = props;
        // Map href (MUI) -> to (react-router)
        return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
    }
);

export const theme = createTheme({
    components: {
        MuiLink: {
            defaultProps: {
                component: LinkBehavior
            }
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior
            }
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    marginTop: 0
                }
            }
        }
    },
    ...customTheme
});
