import { Alert, AlertTitle, Box, Chip, Paper, Stack, Typography } from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from '../entities/profile';
import { ProfileQuestion } from '../entities/profileQuestion';
import { ProfileQuestionResponse, retrieveProfileQuestionResponses } from '../entities/profileQuestionResponse';
import { RootState } from '../entities/store';

function ProfileResponseText(response: ProfileQuestionResponse, question: ProfileQuestion) {
    if (!response.content?.length) {
        return null;
    }
    return (
        <Paper
            elevation={1}
            variant="outlined"
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                whiteSpace: 'pre-line',
                marginBottom: 1,
                paddingTop: 0.5,
                padding: 1,
                background: 'linear-gradient(-45deg, #ffb36305, #fff)'
            }}
        >
            <Stack>
                <Typography variant="h6" color="primary" gutterBottom>
                    {question.name}
                </Typography>
                <Typography variant="body1" color="secondary">
                    {response.content}
                </Typography>
            </Stack>
        </Paper>
    );
}

function ProfileResponseTags(response: ProfileQuestionResponse, question: ProfileQuestion) {
    const options = useRef(
        response.options.map((option) => question.options.filter((questionOption) => questionOption.id === option)[0])!
    );
    return (
        <Paper
            elevation={1}
            variant="outlined"
            sx={{
                marginBottom: 1,
                paddingLeft: 0.5,
                paddingRight: 0.5,
                paddingTop: 0.5,
                paddingBottom: 1,
                background: 'linear-gradient(-45deg, #ffb36305, #fff)'
            }}
        >
            <Typography variant="h6" color="primary" gutterBottom>
                {question.name}
            </Typography>
            <Stack direction="row" spacing={1} flexWrap="wrap" rowGap={1}>
                {question?.icon && (
                    <Box
                        sx={(theme) => ({ fontSize: 22, color: theme.palette.primary.main })}
                        component={MuiIcons[question.icon]}
                    />
                )}
                {options.current.map((option) => (
                    <Chip key={option.id} label={option.name} variant="outlined" color="secondary" size="small" />
                ))}
            </Stack>
        </Paper>
    );
}

function ProfileResponse({ response }: { response: ProfileQuestionResponse }) {
    const question = useSelector((state: RootState) => state.profileQuestion.byId[response.question]);
    if (['text', 'textarea'].includes(question.widgetType)) return ProfileResponseText(response, question);
    if (question.widgetType === 'autocomplete:tags') return ProfileResponseTags(response, question);
    return null;
}

type ProfileResponsesProps = {
    profile: Profile;
};

export function ProfileResponses(props: ProfileResponsesProps) {
    const { profile } = props;
    const dispatch = useDispatch();
    const questionsById = useSelector((state: RootState) => state.profileQuestion.byId);
    const responsesByQuestionId = useSelector(
        (state: RootState) => state.profileQuestionResponse.byProfile[profile.id]
    );
    useEffect(() => {
        if (!responsesByQuestionId) {
            dispatch(retrieveProfileQuestionResponses(profile.id));
        }
    }, []);
    if (!responsesByQuestionId) {
        return null;
    }
    const responses = Object.values(responsesByQuestionId).sort((responseA, responseB) => {
        return (
            (questionsById[responseA.question]?.alternateSortOrder ?? Infinity) -
            (questionsById[responseB.question]?.alternateSortOrder ?? -Infinity)
        );
    });
    return (
        <Box>
            {responses.length > 0 &&
                responses.map((response) => <ProfileResponse key={response.id} response={response} />)}
            {responses.length === 0 && (
                <Alert severity="warning">
                    <AlertTitle>No profile deets - That&lsquo;s kinda sus 🤔</AlertTitle>
                </Alert>
            )}
        </Box>
    );
}
