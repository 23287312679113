import { Autocomplete, Card, CardContent, CardHeader, Checkbox, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { ProfileQuestion, ProfileQuestionOption } from '../../entities/profileQuestion';
import { editProfileQuestionResponse, saveQuestionResponse } from '../../entities/profileQuestionResponse';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../entities/store';
import { Profile } from '../../entities/profile';
import { makeProfileResponseSelector } from '../../entities/selectors/profile';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export interface EditProfileQuestionProps {
    profile: Profile;
    profileQuestion: ProfileQuestion;
}

export function EditProfileQuestion(props: EditProfileQuestionProps) {
    const { profile, profileQuestion } = props;
    const dispatch = useDispatch();
    const profileResponseSelector = makeProfileResponseSelector();
    const response = useSelector((state: RootState) =>
        profileResponseSelector(state, { profile, question: profileQuestion })
    );
    function editResponse(content: string) {
        if (response) {
            dispatch(editProfileQuestionResponse({ response, content }));
        } else {
            dispatch(
                editProfileQuestionResponse({
                    response: {
                        content,
                        lastSavedContent: '',
                        profile: profile.id,
                        question: profileQuestion.id
                    },
                    content
                })
            );
        }
    }

    const save = useCallback(() => {
        if (profile && profileQuestion && response) {
            dispatch(
                saveQuestionResponse({
                    type: 'content',
                    question: profileQuestion.id,
                    profile: profile.id
                })
            );
        }
    }, [response?.profile, response?.question]);

    useEffect(() => {
        return () => save();
    }, [save]);

    return (
        <Card style={{ marginTop: '1vh', marginBottom: '1vh' }}>
            <CardHeader title={profileQuestion.name} />
            <CardContent>
                <TextField
                    placeholder={profileQuestion.description}
                    multiline={profileQuestion.widgetType === 'textarea'}
                    fullWidth
                    rows={10}
                    value={response?.content ?? ''}
                    onChange={(event) => editResponse(event.target.value)}
                    onBlur={() => save()}
                />
            </CardContent>
        </Card>
    );
}

type AutocompleteTagsWidgetOptions = { maxTags?: number };

function initialValues(responseOptions: number[], options: ProfileQuestionOption[]): ProfileQuestionOption[] {
    return responseOptions.map((responseOption) => options.filter((option) => responseOption === option.id)![0]);
}

export function EditProfileQuestionAutocompleteTags(props: EditProfileQuestionProps) {
    const dispatch = useDispatch();
    const options = props.profileQuestion.options;
    const widgetOptions = props.profileQuestion.widgetOptions as AutocompleteTagsWidgetOptions;
    const profileResponseSelector = makeProfileResponseSelector();
    const response = useSelector((state: RootState) =>
        profileResponseSelector(state, { profile: props.profile, question: props.profileQuestion })
    );
    const responseOptions = response?.options ?? [];
    const [values, setValues] = useState<typeof options>(initialValues(responseOptions, options));
    useEffect(() => {
        setValues(initialValues(responseOptions, options));
    }, [response?.options]);
    return (
        <Card sx={{ marginTop: 1 }}>
            <CardHeader title={props.profileQuestion.name} />
            <CardContent sx={{ paddingTop: 0 }}>
                {widgetOptions.maxTags && <Typography variant="body2">Max: {widgetOptions.maxTags}</Typography>}
                <Autocomplete
                    id={`autocomplete-tag-widget-${props.profileQuestion.id}`}
                    disableCloseOnSelect
                    disableClearable
                    options={options}
                    getOptionLabel={(option) => option.name}
                    value={values}
                    multiple
                    fullWidth
                    onChange={(event, newValues) => {
                        if (widgetOptions.maxTags && newValues.length > widgetOptions.maxTags) {
                            event.preventDefault();
                            return;
                        }
                        setValues(newValues);
                        const optionIds = newValues.map((value) => value.id);
                        dispatch(
                            saveQuestionResponse({
                                type: 'response',
                                response: {
                                    id: response?.id,
                                    options: optionIds,
                                    profile: props.profile.id,
                                    question: props.profileQuestion.id
                                }
                            })
                        );
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    )}
                />
            </CardContent>
        </Card>
    );
}
