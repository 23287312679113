import { createSlice } from '@reduxjs/toolkit';
import { retrieveMatches } from './likes';
import { MessageRaw } from './message';
import { logoutUser, UserRetrieveDataRaw } from './user';

export interface ChatGroupMember {
    username: string;
}

export interface ChatRaw {
    description: string;
    group_members: UserRetrieveDataRaw[];
    id: number;
    name: string;
    posts: MessageRaw[];
}

export interface Chat {
    description: string;
    members: number[];
    id: number;
    name: string;
}

export interface ChatState {
    byId: Record<number, Chat>;
}

const initialState: ChatState = {
    byId: {}
};

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(logoutUser.fulfilled, (state) => {
                Object.assign(state, initialState);
            })
            .addCase(retrieveMatches.fulfilled, (state, action) => {
                action.payload.forEach((rawMatch) => {
                    const rawChat = rawMatch.chat_group;
                    state.byId[rawChat.id] = {
                        description: rawChat.description,
                        id: rawChat.id,
                        members: rawChat.group_members.map((member) => member.id),
                        name: rawChat.name
                    };
                });
            });
    }
});

export const chatReducer = chatSlice.reducer;
