import React, { useEffect, useState } from 'react';
import { FormControlLabel, Paper, Stack, Switch, Typography } from '@mui/material';
import { Dispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import http from '../../utils/http';
import { registerLoginCallback } from '../../auth/auth';

const applicationServerKey = process.env.REACT_APP_WEB_PUSH_APPLICATION_SERVER_KEY!;
export let webPushServiceWorkerRegistration: ServiceWorkerRegistration;

type PushRegistrationData = {
    auth: string;
    p256dh: string;
    registrationId: string;
};

function requestNotificationPermission(onGranted: () => void, onDenied: () => void) {
    Notification.requestPermission().then((result) => {
        if (result === 'granted') {
            onGranted();
        } else {
            onDenied();
        }
    });
}

function togglePushNotifications(checked: boolean, dispatch: Dispatch, onChange: (checked: boolean) => void) {
    onChange(checked);
    if (checked) {
        requestNotificationPermission(
            () => {
                registerWithWebPushService(
                    () => onChange(true),
                    () => onChange(false)
                );
            },
            () => {
                alert(
                    'Permission Denied\n\nYour browser has prevented Pineapple Love from using the Notification permission. Please check permission settings and try again.'
                );
                onChange(false);
            }
        );
    } else {
        unregisterWebPushService(
            () => onChange(false),
            () => onChange(true)
        );
    }
}

function urlBase64ToUint8Array(base64String: string): Uint8Array {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function getWebPushSubscription(onSuccess: (subscription: PushSubscription | null) => void, onError: () => void) {
    if ('Notification' in window && webPushServiceWorkerRegistration) {
        webPushServiceWorkerRegistration.pushManager
            .getSubscription()
            .then((subscription) => {
                onSuccess(subscription);
            })
            .catch(onError);
    }
}

function unregisterWebPushService(onSuccess: () => void, onError: () => void) {
    getWebPushSubscription((subscription) => {
        if (subscription) {
            subscription.unsubscribe().then((success) => {
                if (success) {
                    deleteClientPushRegistration(subscription.endpoint, onSuccess, onError);
                }
            });
        }
    }, onError);
}

function registerWithWebPushService(onSuccess: () => void, onError: () => void) {
    if ('Notification' in window && webPushServiceWorkerRegistration) {
        webPushServiceWorkerRegistration.pushManager
            .subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(applicationServerKey)
            })
            .then(function (sub) {
                const registrationId = sub.endpoint;
                const data: PushRegistrationData = {
                    p256dh: btoa(
                        String.fromCharCode.apply(null, new Uint8Array(sub.getKey('p256dh')!) as unknown as number[])
                    ),
                    auth: btoa(
                        String.fromCharCode.apply(null, new Uint8Array(sub.getKey('auth')!) as unknown as number[])
                    ),
                    registrationId: registrationId
                };
                saveClientPushRegistration(data, onSuccess, onError);
            })
            .catch(function (error) {
                alert(`Unable to subscribe to push notification service: ${error}`);
                onError();
            });
    } else {
        onError();
    }
}

function saveClientPushRegistration(data: PushRegistrationData, onSuccess: () => void, onError: () => void) {
    http.create<void, PushRegistrationData>('notifications/push/web/', { data }).then(onSuccess).catch(onError);
}

function deleteClientPushRegistration(registrationId: string, onSuccess: () => void, onError: () => void) {
    http.delete('notifications/push/web/:registrationId', { urlParams: { registrationId: btoa(registrationId) } })
        .then(onSuccess)
        .catch(onError);
}

type Preference = {
    id: number;
    category: 'web_push' | 'email';
    type: 'match' | 'messages' | 'like';
    enabled: boolean;
};

type PreferenceState = {
    id?: number;
    category: 'web_push' | 'email';
    type: 'match' | 'messages' | 'like';
    enabled: boolean;
};

function getNotificationPreferences(onSuccess: (preferences: Preference[]) => void, onError: () => void) {
    http.retrieve<Preference[]>('fast/preferences', { queryParams: { category: 'web_push' } })
        .then(onSuccess)
        .catch(onError);
}

function createNotificationPreference(
    data: Omit<Preference, 'id'>,
    onSuccess: (preference: Preference) => void,
    onError: () => void
) {
    http.create<Preference, Omit<Preference, 'id'>>('fast/preferences/create', { data }).then(onSuccess).catch(onError);
}

function updateNotificationPreference(
    data: Omit<Preference, 'category' | 'type'>,
    onSuccess: (preference: Preference) => void,
    onError: () => void
) {
    http.update<Preference, Omit<Preference, 'category' | 'type'>>('fast/preferences/update', { data })
        .then(onSuccess)
        .catch(onError);
}

export function Notifications(props: { gutterBottom?: boolean }) {
    const [notificationsEnabled, setNotificationsEnabled] = useState(false);
    const [receiveNewMessageNotification, setReceiveNewMessageNotification] = useState<PreferenceState>({
        category: 'web_push',
        type: 'messages',
        enabled: false
    });
    const [receiveNewMatchNotification, setReceiveNewMatchNotification] = useState<PreferenceState>({
        category: 'web_push',
        type: 'match',
        enabled: false
    });
    const [receiveNewLikeNotification, setReceiveNewLikeNotification] = useState<PreferenceState>({
        category: 'web_push',
        type: 'like',
        enabled: false
    });
    const dispatch = useDispatch();

    useEffect(() => {
        getWebPushSubscription(
            (subscription) => {
                if (subscription) {
                    setNotificationsEnabled(true);
                } else {
                    setNotificationsEnabled(false);
                }
            },
            () => setNotificationsEnabled(false)
        );
        getNotificationPreferences(
            (preferences) => {
                preferences.forEach((preference) => {
                    if (preference.type === 'match') {
                        setReceiveNewMatchNotification(preference);
                    } else if (preference.type === 'messages') {
                        setReceiveNewMessageNotification(preference);
                    } else if (preference.type === 'like') {
                        setReceiveNewLikeNotification(preference);
                    }
                });
            },
            () => null
        );
    }, []);

    return (
        <Paper sx={{ padding: 2, width: '100%', margin: props?.gutterBottom ? 1 : 0 }}>
            <Typography variant="body2" color="secondary" sx={{ marginBottom: 2 }}>
                Notifications
            </Typography>
            <Stack flexDirection="column" rowGap={1}>
                <FormControlLabel
                    sx={(theme) => ({
                        margin: 0,
                        justifyContent: 'space-between',
                        width: '100%',
                        '& .MuiFormControlLabel-label': { color: theme.palette.primary.main }
                    })}
                    checked={notificationsEnabled}
                    onChange={(event, checked) => togglePushNotifications(checked, dispatch, setNotificationsEnabled)}
                    control={<Switch />}
                    label="Enable Push Notifications"
                    labelPlacement="start"
                />
                <FormControlLabel
                    sx={(theme) => ({
                        margin: 0,
                        justifyContent: 'space-between',
                        width: '100%',
                        '& .MuiFormControlLabel-label': { color: theme.palette.primary.main }
                    })}
                    disabled={!notificationsEnabled}
                    checked={notificationsEnabled && receiveNewMessageNotification.enabled}
                    onChange={(event, checked) => {
                        setReceiveNewMessageNotification({ ...receiveNewMessageNotification, enabled: checked });
                        if (receiveNewMessageNotification.id) {
                            updateNotificationPreference(
                                { id: receiveNewMessageNotification.id, enabled: checked },
                                (preference) => setReceiveNewMessageNotification(preference),
                                () => setReceiveNewMessageNotification(receiveNewMessageNotification)
                            );
                        } else {
                            createNotificationPreference(
                                {
                                    category: receiveNewMessageNotification.category,
                                    type: receiveNewMessageNotification.type,
                                    enabled: checked
                                },
                                (preference) => setReceiveNewMessageNotification(preference),
                                () => setReceiveNewMessageNotification(receiveNewMessageNotification)
                            );
                        }
                    }}
                    control={<Switch />}
                    label="New Message Notifications"
                    labelPlacement="start"
                />
                <FormControlLabel
                    sx={(theme) => ({
                        margin: 0,
                        justifyContent: 'space-between',
                        width: '100%',
                        '& .MuiFormControlLabel-label': { color: theme.palette.primary.main }
                    })}
                    disabled={!notificationsEnabled}
                    checked={notificationsEnabled && receiveNewMatchNotification.enabled}
                    onChange={(event, checked) => {
                        setReceiveNewMatchNotification({ ...receiveNewMatchNotification, enabled: checked });
                        if (receiveNewMatchNotification.id) {
                            updateNotificationPreference(
                                { id: receiveNewMatchNotification.id, enabled: checked },
                                (preference) => setReceiveNewMatchNotification(preference),
                                () => setReceiveNewMatchNotification(receiveNewMatchNotification)
                            );
                        } else {
                            createNotificationPreference(
                                {
                                    category: receiveNewMatchNotification.category,
                                    type: receiveNewMatchNotification.type,
                                    enabled: checked
                                },
                                (preference) => setReceiveNewMatchNotification(preference),
                                () => setReceiveNewMatchNotification(receiveNewMatchNotification)
                            );
                        }
                    }}
                    control={<Switch />}
                    label="New Match Notifications"
                    labelPlacement="start"
                />
                <FormControlLabel
                    sx={(theme) => ({
                        margin: 0,
                        justifyContent: 'space-between',
                        width: '100%',
                        '& .MuiFormControlLabel-label': { color: theme.palette.primary.main }
                    })}
                    disabled={!notificationsEnabled}
                    checked={notificationsEnabled && receiveNewLikeNotification.enabled}
                    onChange={(event, checked) => {
                        setReceiveNewLikeNotification({ ...receiveNewLikeNotification, enabled: checked });
                        if (receiveNewLikeNotification.id) {
                            updateNotificationPreference(
                                { id: receiveNewLikeNotification.id, enabled: checked },
                                (preference) => setReceiveNewLikeNotification(preference),
                                () => setReceiveNewLikeNotification(receiveNewLikeNotification)
                            );
                        } else {
                            createNotificationPreference(
                                {
                                    category: receiveNewLikeNotification.category,
                                    type: receiveNewLikeNotification.type,
                                    enabled: checked
                                },
                                (preference) => setReceiveNewLikeNotification(preference),
                                () => setReceiveNewLikeNotification(receiveNewLikeNotification)
                            );
                        }
                    }}
                    control={<Switch />}
                    label="New Likes Notifications"
                    labelPlacement="start"
                />
            </Stack>
        </Paper>
    );
}

navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/push-sw.js`, { scope: '/web-push' })
    .then((serviceWorkerRegistration) => {
        webPushServiceWorkerRegistration = serviceWorkerRegistration;
        registerLoginCallback(() => {
            getWebPushSubscription(
                (subscription) => {
                    if (subscription) {
                        registerWithWebPushService(
                            () => null,
                            () => null
                        );
                    }
                },
                () => null
            );
        });
    })
    .catch((error) => console.warn(error));
