import { Button, CardContent, CardHeader, Input, InputLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { theme } from '../theme';
import { AgeFromDateString } from 'age-calculator';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContainer } from './container';
import { useAuth } from './auth';
import { useSelector } from 'react-redux';
import { RootState } from '../entities/store';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { PeakyPassword } from '../components/password';

interface Validation {
    error?: string;
    status: 'pristine' | 'touched' | 'valid' | 'invalid';
}

export function RegisterScreen() {
    const { t } = useTranslation();
    const auth = useAuth();
    const { referralCode } = useParams();
    const error = useSelector((state: RootState) => state.user.error);
    const [birthDate, setBirthDate] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [validationBirthDate, setValidationBirthDate] = useState<Validation>({ status: 'pristine' });
    const [validationPassword, setValidationPassword] = useState<Validation>({ status: 'pristine' });
    const [validationUsername, setValidationUsername] = useState<Validation>({ status: 'pristine' });
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.user.status === 'registered') {
            navigate('/welcome');
        }
    }, [auth]);

    function getAge(birthDate: string): number {
        return new AgeFromDateString(birthDate).age;
    }

    function isRegisterEnabled() {
        return (
            validationUsername.status === 'valid' &&
            validationPassword.status === 'valid' &&
            validationBirthDate.status === 'valid'
        );
    }

    function updateUsername(username: string) {
        const validationUsername: Validation = { status: 'touched' };
        if (username.length < 5) {
            if (validationUsername.status !== 'pristine') {
                validationUsername.error = 'may be at least 5 characters long';
                validationUsername.status = 'invalid';
            }
        } else if (username.length > 20) {
            validationUsername.error = 'may be at most 20 characters';
            validationUsername.status = 'invalid';
        } else if (!/^[a-z0-9]{5,20}$/i.test(username)) {
            validationUsername.error = 'may contain only letters and numbers';
            validationUsername.status = 'invalid';
        } else {
            validationUsername.status = 'valid';
        }
        setUsername(username);
        setValidationUsername(validationUsername);
    }

    function updatePassword(password: string) {
        const validationPassword: Validation = { status: 'touched' };
        if (password.length < 8) {
            if (validationPassword.status !== 'pristine') {
                validationPassword.error =
                    'must have at least 1 letter, 1 number and 1 special character (!@#$%^&*), at least 8 ' +
                    'and at most 50 characters';
                validationPassword.status = 'invalid';
            }
        } else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,50}$/i.test(password)) {
            validationPassword.error =
                'must have at least 1 letter, 1 number and 1 special character (!@#$%^&*), at least 8 ' +
                'and at most 50 characters';
            validationPassword.status = 'invalid';
        } else {
            validationPassword.status = 'valid';
        }
        setPassword(password);
        setValidationPassword(validationPassword);
    }

    function updateBirthDate(birthDate: string) {
        const validationBirthDate: Validation = { status: 'touched' };
        if (birthDate.length === 0) {
            if (!['pristine', 'touched'].includes(validationBirthDate.status)) {
                validationBirthDate.error = 'Gotta provide a birth date';
                validationBirthDate.status = 'invalid';
            }
        } else if (!/^\d{4}-\d{2}-\d{2}$/.test(birthDate)) {
            validationBirthDate.error = 'Gotta provide a birth date';
            validationBirthDate.status = 'invalid';
        } else if (getAge(birthDate) < 19) {
            // silent validation error message
            validationBirthDate.status = 'invalid';
        } else {
            validationBirthDate.status = 'valid';
        }
        setBirthDate(birthDate);
        setValidationBirthDate(validationBirthDate);
    }

    return (
        <AuthContainer logo>
            <CardHeader title={t('signup.card.title')} style={{ color: theme.palette.secondary.main }} />
            <CardContent>
                <Typography color="primary">{t('signup.card.text')}</Typography>
                <Input onChange={(event) => updateUsername(event.target.value)} fullWidth value={username} />
                <InputLabel style={{ color: theme.palette.primary.main }}>username</InputLabel>
                {validationUsername.status === 'invalid' && (
                    <Typography color="error" variant="subtitle2">
                        {validationUsername.error}
                    </Typography>
                )}
                {error?.data?.username && (
                    <Typography color="error" variant="subtitle2">
                        {error.data.username}
                    </Typography>
                )}
                <PeakyPassword
                    onChange={(event) => updatePassword(event.target.value)}
                    fullWidth
                    type="password"
                    value={password}
                    variant="standard"
                />
                <InputLabel style={{ color: theme.palette.primary.main }}>password</InputLabel>
                {validationPassword.status === 'invalid' && (
                    <Typography color="error" variant="subtitle2">
                        {validationPassword.error}
                    </Typography>
                )}
                {error?.data?.password && (
                    <Typography color="error" variant="subtitle2">
                        {error.data.password}
                    </Typography>
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
                        value={birthDate ? dayjs(birthDate) : null}
                        maxDate={dayjs().startOf('day')}
                        onChange={(newBirthDate) => {
                            console.log('newBirthDate', newBirthDate);
                            updateBirthDate(newBirthDate?.format('YYYY-MM-DD') ?? '');
                        }}
                    />
                    <InputLabel style={{ color: theme.palette.primary.main }}>birthdate</InputLabel>
                    {validationBirthDate.status === 'invalid' && (
                        <Typography color="error" variant="subtitle2">
                            {validationBirthDate.error}
                        </Typography>
                    )}
                    {error?.data?.birth_date && (
                        <Typography color="error" variant="subtitle2">
                            {error.data.birth_date}
                        </Typography>
                    )}
                </LocalizationProvider>
            </CardContent>
            <CardContent>
                <Button
                    fullWidth
                    size="medium"
                    variant="contained"
                    color="secondary"
                    onClick={() => auth.register({ username, password, birthDate, referralCode })}
                    disabled={!isRegisterEnabled()}
                >
                    {t('signup.card.button.signup')}
                </Button>
                {error?.data?.referral_code && (
                    <Typography sx={{ paddingTop: 2 }} color="error" variant="subtitle2" textAlign="center">
                        {error.data.referral_code}
                    </Typography>
                )}
                {error?.data?.non_field_errors && (
                    <Typography color="error" variant="subtitle2" textAlign="center" sx={{ paddingTop: 2 }}>
                        {error.data.non_field_errors}
                    </Typography>
                )}
            </CardContent>
        </AuthContainer>
    );
}
