class LinkedList2DElement<T> {
    item: T;

    // 2D grid list traversal
    public down: LinkedList2DElement<T> | undefined;
    public left: LinkedList2DElement<T> | undefined;
    public right: LinkedList2DElement<T> | undefined;
    public up: LinkedList2DElement<T> | undefined;

    // Doubly linked list traversal
    public next: LinkedList2DElement<T> | undefined;
    public previous: LinkedList2DElement<T> | undefined;

    constructor(item: T) {
        this.item = item;
    }
}

export class LinkedList2D<T> {
    public firstElement: LinkedList2DElement<T> | undefined;
    public list: T[];

    public constructor(list: T[], columns: number) {
        this.list = list;
        let currentElement: LinkedList2DElement<T>;
        list.forEach((element: T, index: number) => {
            const column: number = index % columns;
            const previousElement: LinkedList2DElement<T> = currentElement;
            currentElement = new LinkedList2DElement<T>(element);
            currentElement.previous = previousElement;
            if (previousElement) {
                previousElement.next = currentElement;
            }
            if (column > 0) {
                currentElement.left = previousElement;
                currentElement.left.right = currentElement;
            }
            let upElement: LinkedList2DElement<T> | undefined = currentElement;
            for (let reverseIndex = 0; reverseIndex < columns; reverseIndex++) {
                upElement = upElement?.previous;
            }
            if (upElement) {
                currentElement.up = upElement;
                upElement.down = currentElement;
            }
            if (index === 0) {
                this.firstElement = currentElement;
            }
        });
    }

    private regenerateList() {
        let currentElement = this.firstElement;
        const list = [];
        while (currentElement) {
            list.push(currentElement.item);
            currentElement = currentElement.next;
        }
        this.list = list;
    }

    public pushElements(source: LinkedList2DElement<T>, destination: LinkedList2DElement<T>): void {
        let currentElement: LinkedList2DElement<T> | undefined = destination;
        let currentElementItem: T = source.item;
        let nextElementItem: T;
        while (currentElement && currentElement !== source.next) {
            nextElementItem = currentElement.item;
            currentElement.item = currentElementItem;

            currentElement = currentElement.next;
            currentElementItem = nextElementItem;
        }
    }

    public pullElements(source: LinkedList2DElement<T>, destination: LinkedList2DElement<T>): void {
        const sourceElementItem = source.item;
        let currentElement: LinkedList2DElement<T> | undefined = source;
        while (currentElement?.next && currentElement !== destination) {
            currentElement.item = currentElement.next?.item;
            currentElement = currentElement.next;
        }
        currentElement.item = sourceElementItem;
    }

    public moveElementLeft(element: LinkedList2DElement<T>): void {
        if (element.left) {
            this.pushElements(element, element.left);
            this.regenerateList();
        }
    }

    public moveElementRight(element: LinkedList2DElement<T>): void {
        if (element.right) {
            this.pullElements(element, element.right);
            this.regenerateList();
        }
    }

    public moveElementUp(element: LinkedList2DElement<T>): void {
        if (element.up) {
            this.pushElements(element, element.up);
            this.regenerateList();
        }
    }

    public moveElementDown(element: LinkedList2DElement<T>): void {
        if (element.down) {
            this.pullElements(element, element.down);
            this.regenerateList();
        }
    }

    public findElement(item: T): LinkedList2DElement<T> | undefined {
        let currentElement = this.firstElement;
        while (currentElement) {
            if (currentElement.item === item) {
                break;
            }
            currentElement = currentElement.next;
        }
        return currentElement;
    }
}
