import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { FavoriteBorder, Replay, WavingHandOutlined } from '@mui/icons-material';
import { LikeContext } from '../likes/likeContext';
import { BlockProfile } from './blockProfile';
import { useRefreshProfiles } from './refreshProfiles';

interface LikeControlsProps {
    onLike?: () => void;
    onPass?: () => void;
    size?: 'small' | 'large';
    full?: boolean;
    refresh?: boolean;
}

export function LikeControls(props: LikeControlsProps) {
    const likeContext = useContext(LikeContext);
    const { size = 'large', full = false, refresh = false } = props;
    const refreshProfile = useRefreshProfiles();
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                touchAction: 'none',
                padding: 0
            }}
        >
            <Box
                onClick={() => {
                    likeContext?.pass();
                    props.onPass?.();
                }}
                sx={(theme) => ({
                    alignItems: 'center',
                    backgroundColor: theme.likeControl.pass.backgroundColor,
                    border: `0.15em solid ${theme.likeControl.pass.borderColor}`,
                    borderRadius: '50%',
                    boxShadow: `0px 0px 2px ${theme.likeControl.pass.shadowColor}`,
                    display: 'flex',
                    justifyContent: 'center',
                    padding: size === 'large' ? '0.75em' : '0.6em'
                })}
            >
                <WavingHandOutlined
                    sx={(theme) => ({
                        fontSize: size === 'large' ? '2em' : '0.9em',
                        color: theme.likeControl.pass.color
                    })}
                />
            </Box>
            {refresh && (
                <Box
                    onClick={refreshProfile}
                    sx={(theme) => ({
                        alignItems: 'center',
                        backgroundColor: theme.likeControl.refresh.backgroundColor,
                        border: `0.2em solid ${theme.likeControl.refresh.borderColor}`,
                        borderRadius: '50%',
                        boxShadow: `0px 0px 4px ${theme.likeControl.refresh.shadowColor}`,
                        display: 'flex',
                        justifyContent: 'center',
                        padding: size === 'large' ? '0.75em' : '0.6em'
                    })}
                >
                    <Replay
                        sx={(theme) => ({
                            fontSize: size === 'large' ? '1.5em' : '1.25em',
                            color: theme.likeControl.refresh.color
                        })}
                    />
                </Box>
            )}
            {full && <BlockProfile button size={size} />}
            <Box
                onClick={() => {
                    likeContext?.like();
                    props.onLike?.();
                }}
                sx={(theme) => ({
                    alignItems: 'center',
                    backgroundColor: theme.likeControl.like.backgroundColor,
                    border: `0.15em solid ${theme.likeControl.like.borderColor}`,
                    borderRadius: '50%',
                    boxShadow: `0px 0px 2px ${theme.likeControl.like.shadowColor}`,
                    display: 'flex',
                    justifyContent: 'center',
                    padding: size === 'large' ? '0.75em' : '0.6em'
                })}
            >
                <FavoriteBorder
                    sx={(theme) => ({
                        fontSize: size === 'large' ? '2em' : '0.9em',
                        color: theme.likeControl.like.color
                    })}
                />
            </Box>
        </Box>
    );
}
