import {
    Box,
    Button,
    CircularProgress,
    CircularProgressProps,
    Paper,
    ThemeOptions,
    ThemeProvider,
    Typography,
    useTheme
} from '@mui/material';
import { AnimatePresence, LayoutGroup, motion, useMotionValue, useTransform } from 'framer-motion';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Profile, retrieveProfiles } from '../entities/profile';
import { retrieveUserProfileQuestions } from '../entities/profileQuestion';
import { RootState } from '../entities/store';
import { Trans } from 'react-i18next';
import { ProfileAlbum } from '../components/album';
import { ExpandMore, LocationOnOutlined, Replay, RocketLaunch } from '@mui/icons-material';
import { ProfileResponses } from '../components/profile';
import { LikeControls } from '../components/controls';
import { LikeContext, LikeProvider } from '../likes/likeContext';
import { createTheme } from '@mui/material/styles';
import http from '../utils/http';
import { useRefreshProfiles } from '../components/refreshProfiles';

interface SwipeCardProps {
    profile: Profile;
    onDetailClose?: () => void;
    onLike?: () => void;
    onPass?: () => void;
    showDetail?: boolean;
    showControls?: boolean;
    showCloseDetail?: boolean;
}

const albumVariants = {
    teaser: {
        transform: 'scale(1.1)'
    },
    detailed: {
        transform: 'scale(1)'
    }
};

const openDetailButtonVariants = {
    teaser: {
        transform: 'rotate(0)'
    },
    detailed: {
        transform: 'rotate(180deg)'
    }
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};

export interface Flare {
    name: string;
    provider: string;
    theme: ThemeOptions;
}

export async function getProfileFlares(
    successCallback: (flares: Record<string, Flare>) => void,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    errorCallback?: () => void
) {
    const flares = await http.retrieve<Record<string, Flare>>('/fast/profile/flare', {
        queryParams: { provider: 'mui:v5' }
    });
    successCallback(flares);
}

export function ThemedSwipeCard(props: SwipeCardProps) {
    const [flare, setFlare] = useState<Flare | undefined>();
    useEffect(() => {
        if (props.profile.flare !== undefined) {
            getProfileFlares((flares) => setFlare(flares[props.profile.flare!]));
        }
    }, []);

    if (flare) {
        const profileFlareTheme = createTheme({ ...flare.theme });
        return (
            <ThemeProvider theme={profileFlareTheme}>
                <SwipeCard {...props} />
            </ThemeProvider>
        );
    }
    return <SwipeCard {...props} />;
}

interface FlareIconProps {
    icon: React.ReactElement | React.ReactText;
    horizontalFlip?: boolean | undefined;
    style?: React.CSSProperties | undefined;
}
export function FlareIcon(props: FlareIconProps): React.ReactElement {
    const style: React.CSSProperties = props.style ?? {};
    if (props.horizontalFlip) {
        style.transform = 'scaleX(-1)';
    }
    return <span style={style}>{props.icon}</span>;
}

export function SwipeCard(props: SwipeCardProps) {
    const { showControls = true, showCloseDetail = true } = props;
    const likeContext = useContext(LikeContext);
    const x = useMotionValue(0);
    const likeOpacity = useTransform(x, [0, 100], [0, 1]);
    const dislikeOpacity = useTransform(x, [-100, 0], [1, 0]);
    const [detailed, setDetailed] = useState(props.showDetail ?? false);
    const scrollRef = useRef<HTMLDivElement>();
    const theme = useTheme();

    useEffect(() => {
        if (!detailed) {
            scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [detailed]);
    const profileDetailVariants = {
        teaser: {
            top: '-2.5em',
            marginBottom: '-2.5em'
        },
        detailed: {
            top: 0,
            marginBottom: showControls ? '5em' : 0
        }
    };
    return (
        <Box
            component={motion.div}
            sx={(theme) => ({
                background: theme.profileFlare?.background ?? theme.palette.background.default,
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                display: 'flex',
                padding: 0.5,
                zIndex: 1
            })}
            style={{ x }}
            drag={detailed ? false : 'x'}
            dragElastic={1}
            dragSnapToOrigin
            onContextMenu={(event) => event.preventDefault()}
            onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);
                if (swipe < -swipeConfidenceThreshold) {
                    likeContext?.pass();
                    props.onPass?.();
                } else if (swipe > swipeConfidenceThreshold) {
                    likeContext?.like();
                    props.onLike?.();
                }
            }}
        >
            <Paper
                sx={{
                    flexGrow: 1,
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <motion.div
                    style={{
                        position: 'absolute',
                        top: 20,
                        left: 30,
                        fontSize: '8em',
                        transform: 'rotate(-20deg)',
                        zIndex: 2,
                        opacity: likeOpacity
                    }}
                >
                    ❤️
                </motion.div>
                <motion.div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 50,
                        fontSize: '8em',
                        transform: 'rotate(70deg)',
                        zIndex: 2,
                        opacity: dislikeOpacity
                    }}
                >
                    👋
                </motion.div>
                <LayoutGroup>
                    <Box
                        sx={{
                            height: '100%',
                            overflowX: 'hidden',
                            overflowY: detailed ? 'scroll' : 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                            scrollbarWidth: 'none',
                            overscrollBehavior: 'contain'
                        }}
                    >
                        <Box component="span" ref={scrollRef} />
                        <Box
                            component={motion.div}
                            variants={albumVariants}
                            initial="teaser"
                            animate={detailed ? 'detailed' : 'teaser'}
                            transition={{
                                transform: { type: 'spring', stiffness: 300, damping: 30 },
                                top: { type: 'spring', stiffness: 300, damping: 30 },
                                zIndex: { type: 'spring', stiffness: 300, damping: 30 }
                            }}
                            sx={{
                                position: 'relative',
                                flex: 1
                            }}
                        >
                            <ProfileAlbum profile={props.profile} disabled={!detailed} />
                        </Box>
                        <Box
                            component={motion.div}
                            variants={profileDetailVariants}
                            initial="teaser"
                            animate={detailed ? 'detailed' : 'teaser'}
                            sx={(theme) => ({
                                flexGrow: 1,
                                position: 'relative',
                                top: '-2.5em',
                                backgroundColor: theme.palette.background.paper,
                                padding: 1
                            })}
                        >
                            {showCloseDetail && (
                                <Box
                                    style={{
                                        position: 'absolute',
                                        top: '0em',
                                        display: 'flex',
                                        left: 0,
                                        right: 0,
                                        zIndex: 5,
                                        justifyContent: 'right'
                                    }}
                                >
                                    <Box
                                        onClick={() => {
                                            setDetailed(!detailed);
                                            if (detailed) {
                                                props.onDetailClose?.();
                                            }
                                        }}
                                        component={motion.div}
                                        sx={(theme) => ({
                                            top: '0.5em',
                                            position: 'relative',
                                            right: '1em',
                                            alignItems: 'center',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            border: `2px solid ${theme.palette.secondary.main}`,
                                            backgroundColor: theme.palette.secondary.main
                                        })}
                                        variants={openDetailButtonVariants}
                                        animate={detailed ? 'detailed' : 'teaser'}
                                    >
                                        <ExpandMore sx={{ color: 'white', fontSize: '2em' }} />
                                    </Box>
                                </Box>
                            )}
                            <Typography
                                color="primary"
                                variant="h6"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    columnGap: '8px',
                                    alignItems: 'center'
                                }}
                            >
                                {theme.profileFlare && <FlareIcon icon={theme.profileFlare?.icon} horizontalFlip />}
                                <span>
                                    {props.profile.username} - {props.profile.age}
                                </span>
                                {theme.profileFlare && <FlareIcon icon={theme.profileFlare?.icon} />}
                            </Typography>
                            <Typography
                                color="secondary"
                                variant="body2"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingBottom: 1,
                                    position: 'relative',
                                    left: -2
                                }}
                            >
                                {props.profile.location && <LocationOnOutlined />}
                                {!props.profile.location && <RocketLaunch sx={{ marginRight: 1 }} />}
                                <span>{props.profile.location?.name ?? 'Mars'}</span>
                            </Typography>
                            <ProfileResponses profile={props.profile} />
                        </Box>
                        <AnimatePresence>
                            {detailed && showControls && (
                                <Box
                                    sx={{
                                        paddingBottom: 2,
                                        paddingTop: 2,
                                        position: 'absolute',
                                        left: 0,
                                        right: 0,
                                        bottom: '1em'
                                    }}
                                    component={motion.div}
                                    initial={{ opacity: 0, height: 0, padding: 0 }}
                                    animate={{ opacity: 1, height: 'auto', paddingTop: 2, paddingBottom: 2 }}
                                    exit={{ opacity: 0, height: 0, padding: 0 }}
                                    key="controls"
                                >
                                    <LikeControls onLike={props.onLike} onPass={props.onPass} full refresh />
                                </Box>
                            )}
                        </AnimatePresence>
                    </Box>
                </LayoutGroup>
            </Paper>
        </Box>
    );
}

const swipeCardLoadingIndicatorColours: CircularProgressProps['color'][] = ['secondary', 'primary'];
function ColourShiftCircularProgress(props: Omit<CircularProgressProps, 'color'>) {
    const [colour, setColour] = useState(0);
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

    function setTimer() {
        timer.current = setTimeout(() => {
            colour === swipeCardLoadingIndicatorColours.length - 1 ? setColour(0) : setColour(colour + 1);
            timer.current = null;
        }, 1400);
    }
    if (!timer.current) {
        setTimer();
    }
    return <CircularProgress {...props} color={swipeCardLoadingIndicatorColours[colour]} />;
}

export function SwipeScreen() {
    const {
        byId: profilesById,
        profiles,
        userProfile,
        requestStatus
    } = useSelector((state: RootState) => state.profile);
    const dispatch = useDispatch();
    const refreshProfiles = useRefreshProfiles();
    useEffect(() => {
        dispatch(retrieveUserProfileQuestions());
    }, []);
    useEffect(() => {
        if (profiles.length === 0) {
            dispatch(retrieveProfiles());
        }
    }, [profiles.length]);
    if (userProfile && !userProfile.location) {
        return (
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1,
                    height: '100%'
                }}
            >
                <Typography variant="h5" color="secondary" align="center" sx={{ marginBottom: 4 }}>
                    Before you can see profiles
                    <br />
                    set your location
                </Typography>
                <Button to="/settings" color="secondary" variant="contained">
                    Settings
                </Button>
            </Box>
        );
    }

    if (requestStatus === 'pending') {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 6,
                    flexGrow: 1,
                    height: '100%'
                }}
            >
                <Typography variant="h4" color="primary" textAlign="center">
                    Picking some
                </Typography>
                <ColourShiftCircularProgress size={50} />
                <Typography variant="h4" color="secondary" textAlign="center">
                    fresh pineapples
                </Typography>
            </Box>
        );
    }

    if (profiles.length === 0) {
        return (
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    flexGrow: 1,
                    height: '100%'
                }}
            >
                <Typography variant="h4" color="primary" align="center">
                    <Trans i18nKey="swipe.noProfiles" />
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 3 }}>
                    <Typography
                        variant="h5"
                        align="center"
                        sx={(theme) => ({ color: theme.likeControl.refresh.backgroundColorDark })}
                    >
                        Refresh everyone
                        <br /> you&rsquo;ve passed on
                    </Typography>
                    <Button
                        startIcon={<Replay />}
                        size="large"
                        variant="contained"
                        color="secondary"
                        onClick={refreshProfiles}
                        sx={(theme) => ({
                            alignItems: 'normal',
                            backgroundColor: theme.likeControl.refresh.backgroundColor,
                            '&:hover': {
                                backgroundColor: theme.likeControl.refresh.backgroundColorDark
                            }
                        })}
                    >
                        <span>Refresh</span>
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            style={{
                display: 'flex',
                height: '100%',
                overflow: 'hidden',
                position: 'relative'
            }}
        >
            {(profiles ?? [])
                .slice(0, 3)
                .reverse()
                .map((profileId: number): React.ReactNode => {
                    const profile = profilesById[profileId];
                    return (
                        <LikeProvider profile={profile} key={profile.id.toString()}>
                            <ThemedSwipeCard
                                showDetail={window.innerHeight <= 775}
                                showCloseDetail={window.innerHeight > 775}
                                profile={profile}
                            />
                        </LikeProvider>
                    );
                })}
        </Box>
    );
}
