import React, { useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, SvgIcon } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { AccountCircleOutlined, ChatOutlined, FavoriteBorderOutlined, WhatshotOutlined } from '@mui/icons-material';
import { useAuth } from '../auth/auth';

interface AppNavigationConfig {
    [value: string]: {
        default?: true;
        icon: typeof SvgIcon;
        to: string;
    };
}

const NAVIGATION_CONFIG: AppNavigationConfig = {
    swipe: {
        default: true,
        icon: WhatshotOutlined,
        to: '/swipe'
    },
    likes: {
        icon: FavoriteBorderOutlined,
        to: '/likes'
    },
    messages: {
        icon: ChatOutlined,
        to: '/matches'
    },
    profile: {
        icon: AccountCircleOutlined,
        to: '/account'
    }
};

export function AppBottomNavigation() {
    const auth = useAuth();
    const location = useLocation();
    const [bottomNavigation, setBottomNavigation] = useState('');

    useEffect(() => {
        Object.keys(NAVIGATION_CONFIG).forEach((configName: string) => {
            const config = NAVIGATION_CONFIG[configName];
            if (location.pathname.startsWith(config.to)) {
                setBottomNavigation(configName);
                return true;
            }
        });
    }, []);

    if (auth.user.status !== 'loggedIn') {
        return null;
    }
    return (
        <BottomNavigation
            value={bottomNavigation}
            onChange={(event, newValue) => setBottomNavigation(newValue)}
            showLabels
            sx={{
                background: (theme) => theme.palette.background.default,
                flexShrink: 0
            }}
        >
            {Object.keys(NAVIGATION_CONFIG).map((configName: string): React.ReactNode => {
                const config = NAVIGATION_CONFIG[configName];
                return (
                    <BottomNavigationAction
                        component={Link}
                        icon={<config.icon style={{ fontSize: 40 }} />}
                        key={configName}
                        to={config.to}
                        value={configName}
                        sx={{ minWidth: 60 }}
                    />
                );
            })}
        </BottomNavigation>
    );
}
//
// export const AppBottomNavigation = withRouter(
//     withStyles(styles)(
//         class AppBottomNavigation extends React.PureComponent<AppStyleProps & RouteComponentProps> {
//             public state: { bottomNavigation: string } = {
//                 bottomNavigation: ''
//             };
//
//             public navigationConfig: AppNavigationConfig = {
//                 swipe: {
//                     default: true,
//                     icon: WhatshotOutlined,
//                     to: '/swipe'
//                 },
//                 likes: {
//                     icon: FavoriteBorderOutlined,
//                     to: '/likes'
//                 },
//                 messages: {
//                     icon: ChatOutlined,
//                     to: '/matches'
//                 },
//                 profile: {
//                     icon: AccountCircleOutlined,
//                     to: '/account'
//                 }
//             };
//
//             public componentDidMount() {
//                 this.highlightIcon();
//             }
//
//             public componentDidUpdate() {
//                 this.highlightIcon();
//             }
//
//             public highlightIcon() {
//                 Object.keys(this.navigationConfig).forEach((configName: string) => {
//                     const config = this.navigationConfig[configName];
//                     if (this.props.location.pathname.startsWith(config.to)) {
//                         this.setState({ bottomNavigation: configName });
//                         return true;
//                     }
//                 });
//             }
//
//             public render() {
//                 return (
//                     <BottomNavigation
//                         value={this.state.bottomNavigation}
//                         onChange={(event, newValue) => this.setState({ bottomNavigation: newValue })}
//                         showLabels
//                         className={this.props.classes.bottomNavigation}
//                     >
//                         {Object.keys(this.navigationConfig).map((configName: string): React.ReactNode => {
//                             const config = this.navigationConfig[configName];
//                             return (
//                                 <BottomNavigationAction
//                                     component={Link}
//                                     icon={<config.icon style={{ fontSize: 40 }} />}
//                                     key={configName}
//                                     to={config.to}
//                                     value={configName}
//                                 />
//                             );
//                         })}
//                     </BottomNavigation>
//                 );
//             }
//         }
//     )
// );
