import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http, { ResponseError } from '../utils/http';
import { retrieveMatches } from './likes';
import { addMessage } from './message';
import { retrieveUserProfile } from './profile';

export interface UserRetrieveDataRaw {
    age: number;
    date_joined: string;
    id: number;
    username: string;
}

export interface UserRetrieveData {
    age: number;
    dateJoined: string;
    id: number;
    username: string;
}

interface UserLoginDataRaw extends UserLoginData {
    password: string;
    username: string;
}
interface UserRegisterDataRaw extends UserLoginDataRaw {
    birth_date: string;
    referral_code?: string | undefined;
}

export interface User {
    age: number;
    dateJoined?: string;
    id: number;
    username: string;
}
export interface UserLoginData {
    password: string;
    username: string;
}
export interface UserRegisterData extends UserLoginData {
    birthDate: string;
    referralCode?: string | undefined;
}
export interface UserState {
    byId: Record<number, User>;
    error?: ResponseError;
    requestStatus: 'init' | 'pending' | 'complete' | 'error';
    status: 'init' | 'loggedIn' | 'loggedOut' | 'registered';
    user?: User;
}

const initialState: UserState = {
    byId: {},
    requestStatus: 'init',
    status: 'init'
};

export const retrieveUser = createAsyncThunk<UserRetrieveDataRaw, void, { rejectValue: ResponseError }>(
    'user/retrieve',
    async (_, thunkApi) => {
        try {
            return await http.retrieve<UserRetrieveDataRaw>('/auth/user/');
        } catch (error) {
            return thunkApi.rejectWithValue(error as ResponseError);
        }
    }
);

export const loginUser = createAsyncThunk<void, UserLoginData, { rejectValue: ResponseError }>(
    'user/login',
    async (data, thunkAPI) => {
        try {
            await http.create<void, UserLoginData>('/auth/login/', { data });
            thunkAPI.dispatch(retrieveUser());
            thunkAPI.dispatch(retrieveUserProfile());
        } catch (error) {
            return thunkAPI.rejectWithValue(error as ResponseError);
        }
    }
);

export const logoutUser = createAsyncThunk<void, void, { rejectValue: ResponseError }>(
    'user/logout',
    async (data, thunkAPI) => {
        try {
            await http.create<void, void>('/auth/logout/');
        } catch (error) {
            return thunkAPI.rejectWithValue(error as ResponseError);
        }
    }
);

export const registerUser = createAsyncThunk<void, UserRegisterData, { rejectValue: ResponseError }>(
    'user/register',
    async (data, thunkAPI) => {
        try {
            await http.create<void, UserRegisterDataRaw>('/auth/register/', {
                data: {
                    birth_date: data.birthDate,
                    password: data.password,
                    username: data.username,
                    referral_code: data.referralCode
                }
            });
            thunkAPI.dispatch(retrieveUser());
            thunkAPI.dispatch(retrieveUserProfile());
        } catch (error) {
            return thunkAPI.rejectWithValue(error as ResponseError);
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(retrieveUser.fulfilled, (state, action) => {
                const rawUser = action.payload;
                state.user = {
                    age: rawUser.age,
                    dateJoined: rawUser.date_joined,
                    id: rawUser.id,
                    username: rawUser.username
                };
                state.status = 'loggedIn';
                state.requestStatus = 'complete';
            })
            .addCase(retrieveUser.rejected, (state, action) => {
                state.error = action.payload;
                if (['NOT_AUTHORIZED', 'NOT_AUTHENTICATED'].includes(action.payload?.type ?? 'UNKNOWN_ERROR')) {
                    state.requestStatus = 'complete';
                    state.status = 'loggedOut';
                } else {
                    state.requestStatus = 'error';
                }
            })
            .addCase(retrieveUser.pending, (state, action) => {
                state.error = action.payload;
                state.requestStatus = 'pending';
            })
            .addCase(loginUser.fulfilled, (state) => {
                state.requestStatus = 'complete';
                state.status = 'loggedIn';
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.error = action.payload;
                state.requestStatus = 'error';
            })
            .addCase(loginUser.pending, (state) => {
                state.requestStatus = 'pending';
                state.error = undefined;
            })
            .addCase(registerUser.fulfilled, (state) => {
                state.requestStatus = 'complete';
                state.status = 'registered';
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.error = action.payload;
                state.requestStatus = 'error';
            })
            .addCase(registerUser.pending, (state) => {
                state.requestStatus = 'pending';
                state.error = undefined;
            })
            .addCase(logoutUser.fulfilled, (state) => {
                state.user = undefined;
                state.requestStatus = 'complete';
                state.status = 'loggedOut';
            })
            .addCase(logoutUser.rejected, (state, action) => {
                state.error = action.payload;
                state.requestStatus = 'error';
            })
            .addCase(logoutUser.pending, (state) => {
                state.requestStatus = 'pending';
                state.error = undefined;
            })
            .addCase(addMessage, (state, action) => {
                const userRaw = action.payload.author;
                state.byId[userRaw.id] = {
                    age: userRaw.age,
                    id: userRaw.id,
                    username: userRaw.username
                };
            })
            .addCase(retrieveMatches.fulfilled, (state, action) => {
                action.payload.forEach((matchRaw) => {
                    matchRaw.chat_group.group_members.map((userRaw) => {
                        state.byId[userRaw.id] = {
                            age: userRaw.age,
                            id: userRaw.id,
                            username: userRaw.username
                        };
                    });
                });
            });
    }
});

export const userReducer = userSlice.reducer;
