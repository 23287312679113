import { Box, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { retrieveMatches } from '../entities/likes';
import { MatchWithProfile, selectMatchesWithProfiles } from '../entities/selectors/likes';
import { RootState } from '../entities/store';
import { lastMessageByChatSelector } from './selectors';
import { Avatar } from '../components/avatar';
import { Trans } from 'react-i18next';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
    relativeTime: {
        s: 'now',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        M: '1 month',
        MM: '%d months',
        y: '1 year',
        yy: '%d years'
    }
});

const LASTEST_ACTIVITY_INTERVAL = 15 * 1000;

function getLatestActivity(latestActivity: string): string {
    const withoutSuffix = true;
    return dayjs.utc().to(dayjs.utc(latestActivity), withoutSuffix);
}

function LatestActivity(props: { latestActivity: string }): React.ReactElement {
    const [relativeTime, setRelativeTime] = useState(getLatestActivity(props.latestActivity));
    useEffect(() => {
        const intervalSubscription = setInterval(() => {
            setRelativeTime(getLatestActivity(props.latestActivity));
        }, LASTEST_ACTIVITY_INTERVAL);
        return () => clearInterval(intervalSubscription);
    }, [props.latestActivity]);
    return <>{relativeTime}</>;
}

export function MatchesScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const lastMessageByProfile = useSelector(lastMessageByChatSelector);
    const matches = useSelector(selectMatchesWithProfiles);
    const user = useSelector((state: RootState) => state.user.user);

    const openChat = (match: MatchWithProfile) => {
        navigate(`/matches/${match.id}`);
    };

    useEffect(() => {
        dispatch(retrieveMatches());
    }, []);

    if (matches.length === 0) {
        return (
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    height: '100%'
                }}
            >
                <Typography variant="h5" color="secondary" align="center">
                    <Trans i18nKey="matches.noMatches" />
                </Typography>
            </Box>
        );
    }
    return (
        <Box sx={(theme) => ({ backgroundColor: theme.palette.background.default, height: '100%' })}>
            <List>
                {matches.map((match) => {
                    const lastMessage = match?.chat ? lastMessageByProfile[match.chat] : undefined;
                    return (
                        <ListItem key={match.id} onClick={() => openChat(match)}>
                            <ListItemAvatar>
                                <Avatar
                                    alt={match.profile.username}
                                    src={match.profile.albums[0].media?.[0]?.url}
                                    sx={{ height: 70, width: 70, marginRight: 2 }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Stack flexDirection="row" justifyContent="space-between">
                                        <span>{match.profile.username}</span>
                                        <LatestActivity latestActivity={match.latestActivity} />
                                    </Stack>
                                }
                                secondary={
                                    <Typography variant="body2" color="textPrimary" noWrap>
                                        {lastMessage && user && lastMessage.author === user.id && 'You: '}
                                        {lastMessage?.message ?? 'No messages 👻'}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
}
