import { AnyAction, AsyncThunk, createAsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import http, { ResponseError } from '../utils/http';
import { Album, AlbumRaw, setAlbumsFromRaw } from './album';
import { createLikeFulfilledAction } from './likes';
import { logoutUser } from './user';
import { camelToSnakeString, keyTransform } from '../utils/object';

export interface Location {
    country: string;
    latitude: number;
    name: string;
    county: string;
    id: number;
    region: string;
    longitude: number;
}

export interface ProfileRaw {
    albums: AlbumRaw[];
    id: number;
    is_visible: boolean;
    user: {
        age: number;
        username: string;
    };
    location_detailed: Location | undefined | null;
    filters: {
        distance: number;
    };
    flare: string | undefined | null;
}

export interface Profile {
    age: number;
    albums: Album[];
    id: number;
    isVisible: boolean;
    flare?: string | undefined;
    username: string;
    location?: Location;
    filters?: {
        distance: number;
    };
}

export interface ProfileUpdate {
    id: number;
    filters: {
        distance: number;
    };
    isVisible: boolean;
    location: number;
}

export interface ProfileUpdateRaw {
    filters: {
        distance: number;
    };
    is_visible: boolean;
    location: number;
}

export interface ProfileBlock {
    profile: number;
}

export interface ProfileState {
    error?: ResponseError;
    byId: { [id: number]: Profile };
    byUser: { [user: string]: Profile };
    profiles: number[];
    requestStatus: 'init' | 'pending' | 'complete' | 'error';
    userProfile?: Profile;
}

const initialState: ProfileState = {
    byId: {},
    byUser: {},
    profiles: [],
    requestStatus: 'init'
};

export const retrieveUserProfile = createAsyncThunk<ProfileRaw, void, { rejectValue: ResponseError }>(
    'profile/retrieve/user',
    async (_, thunkApi) => {
        try {
            const profileRaw = await http.retrieve<ProfileRaw>('/profile/');
            thunkApi.dispatch(setAlbumsFromRaw(profileRaw.albums));
            return profileRaw;
        } catch (error) {
            return thunkApi.rejectWithValue(error as ResponseError);
        }
    }
);

export const updateUserProfile = createAsyncThunk<ProfileRaw, Partial<ProfileUpdate>, { rejectValue: ResponseError }>(
    'profile/retrieve/user',
    async (profile, thunkApi) => {
        const { id, ...data } = profile;
        try {
            const profileRaw = await http.update<ProfileRaw, Partial<ProfileUpdateRaw>>(
                `/profile/update/${id}/`,
                {
                    data: keyTransform(data, camelToSnakeString)
                },
                'PATCH'
            );
            if ((profile.filters && 'distance' in profile.filters) || 'location' in profile) {
                thunkApi.dispatch(retrieveProfiles());
            }
            return profileRaw;
        } catch (error) {
            return thunkApi.rejectWithValue(error as ResponseError);
        }
    }
);

export const blockProfile = createAsyncThunk<void, ProfileBlock, { rejectValue: ResponseError }>(
    'profile/block/',
    async (requestData, thunkApi) => {
        try {
            return await http.create<void, ProfileBlock>('/profile/block/', { data: requestData });
        } catch (error) {
            return thunkApi.rejectWithValue(error as ResponseError);
        }
    }
);

export const retrieveProfile = createAsyncThunk<ProfileRaw, number, { rejectValue: ResponseError }>(
    'profile/retrieve',
    async (profileId, thunkApi) => {
        try {
            return await http.retrieve<ProfileRaw>('/profile/:id/', {
                urlParams: {
                    id: profileId
                }
            });
        } catch (error) {
            return thunkApi.rejectWithValue(error as ResponseError);
        }
    }
);

export const retrieveProfiles = createAsyncThunk<ProfileRaw[], void, { rejectValue: ResponseError }>(
    'profile/match/retrieve',
    async (_, thunkApi) => {
        try {
            return await http.retrieve<ProfileRaw[]>('/match/profile/list/');
        } catch (error) {
            return thunkApi.rejectWithValue(error as ResponseError);
        }
    }
);

type GenericAsyncThunk = AsyncThunk<unknown, unknown, never>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

const profilePendingAction = (action: AnyAction): action is PendingAction => {
    return action.type.startsWith('profile/') && action.type.endsWith('/pending');
};
const profileRejectedAction = (action: AnyAction): action is RejectedAction => {
    return action.type.startsWith('profile/') && action.type.endsWith('/rejected');
};

type ProfileRetrieveAsyncThunk = AsyncThunk<ProfileRaw, unknown, never>;
type ProfileRetrieveFulfilledAction = ReturnType<ProfileRetrieveAsyncThunk['fulfilled']>;

const profileRetrieveFulfilledAction = (action: AnyAction): action is ProfileRetrieveFulfilledAction => {
    return action.type.startsWith('profile/retrieve') && action.type.endsWith('fulfilled');
};

const updateStateRetrieveProfile = (state: Draft<ProfileState>, rawProfile: ProfileRaw): Profile => {
    const profile = {
        age: rawProfile.user.age,
        albums: rawProfile.albums,
        id: rawProfile.id,
        isVisible: rawProfile.is_visible,
        username: rawProfile.user.username,
        location: rawProfile.location_detailed ?? undefined,
        filters: rawProfile.filters,
        flare: rawProfile.flare ?? undefined
    };
    state.byId[rawProfile.id] = profile;
    state.byUser[rawProfile.user.username] = profile;
    return profile;
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        addProfile: (state, action: PayloadAction<Profile>) => {
            state.byId[action.payload.id] = action.payload;
            state.byUser[action.payload.username] = action.payload;
        },
        setProfileFromRaw: (state: Draft<ProfileState>, action: PayloadAction<ProfileRaw>) => {
            const profileRaw = action.payload;
            updateStateRetrieveProfile(state, profileRaw);
        },
        removeSwipedProfile: (state) => {
            state.profiles.shift();
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(logoutUser.fulfilled, (state) => {
                Object.assign(state, initialState);
            })
            .addCase(retrieveProfiles.fulfilled, (state, action) => {
                const rawProfiles = action.payload;
                state.profiles = [];
                rawProfiles.forEach((rawProfile: ProfileRaw) => {
                    const profile = updateStateRetrieveProfile(state, rawProfile);
                    state.profiles.push(profile.id);
                });
                state.requestStatus = 'complete';
            })
            .addCase(blockProfile.fulfilled, (state, action) => {
                const profile = state.byId[action.meta.arg.profile];
                if (profile) {
                    delete state.byId[profile.id];
                    delete state.byUser[profile.username];
                    state.profiles = state.profiles.filter((profileId) => profileId !== action.meta.arg.profile);
                }
                state.requestStatus = 'complete';
            })
            .addMatcher(profileRetrieveFulfilledAction, (state, action) => {
                const rawProfile = action.payload;
                const profile = updateStateRetrieveProfile(state, rawProfile);
                if (action.type === retrieveUserProfile.fulfilled.type) {
                    state.userProfile = profile;
                }
                state.requestStatus = 'complete';
            })
            .addMatcher(profilePendingAction, (state) => {
                state.requestStatus = 'pending';
            })
            .addMatcher(profileRejectedAction, (state, action) => {
                state.error = action.payload;
                state.requestStatus = 'error';
            })
            .addMatcher(createLikeFulfilledAction, (state, action) => {
                state.profiles = state.profiles.filter((profile) => profile !== action.payload.profile.id);
            });
    }
});

export const removeSwipedProfile = profileSlice.actions.removeSwipedProfile;
export const setProfileFromRaw = profileSlice.actions.setProfileFromRaw;
export const addProfile = profileSlice.actions.addProfile;
export const profileReducer = profileSlice.reducer;
