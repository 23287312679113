import React, { useEffect, useState } from 'react';
import http from '../../utils/http';
import { Autocomplete, Paper, TextField } from '@mui/material';
import { Location, Profile, updateUserProfile } from '../../entities/profile';
import { useDispatch } from 'react-redux';

interface Autocomplete {
    label: string;
    location: Location;
}

export function LocationInput(props: { profile: Profile; gutterBottom?: boolean }) {
    const dispatch = useDispatch();
    const [options, setOptions] = useState<Autocomplete[]>([]);
    useEffect(() => {
        (async () => {
            const locationsResponse = await http.retrieve<Location[]>('/fast/profile/locations');
            const locations: Autocomplete[] = (locationsResponse ?? []).map((location: Location): Autocomplete => {
                return {
                    label: `${location.name}, ${location?.county ? `${location.county}, ` : ''}${location.region}, ${
                        location.country
                    }`,
                    location
                };
            });
            setOptions(locations);
        })();
    }, []);

    if (!props.profile || !options.length) {
        return <></>;
    }

    const profileLocation = options.find((location) => location.location.id === props.profile.location?.id);
    return (
        <Paper sx={{ padding: 2, width: '100%', margin: props?.gutterBottom ? 1 : 0 }}>
            <Autocomplete
                disablePortal
                id="location-picker"
                options={options}
                value={profileLocation ?? null}
                fullWidth
                onChange={(event, option) =>
                    option && dispatch(updateUserProfile({ id: props.profile.id, location: option.location.id }))
                }
                renderInput={(params) => <TextField {...params} label="Set your location" />}
            />
        </Paper>
    );
}
