import { Button, CardContent, CardHeader } from '@mui/material';
import { theme } from '../theme';
import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../entities/store';
import { AuthContainer } from './container';

export function WelcomeScreen() {
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.user.user);

    return (
        <AuthContainer>
            <CardHeader title={t('signupWelcome.card.title')} style={{ color: theme.palette.primary.dark }} />
            <CardContent>
                <Trans
                    i18nKey="signupWelcome.card.message"
                    components={{
                        p: <p style={{ color: theme.palette.secondary.main }} />,
                        ul: <ul />,
                        li: <li style={{ color: theme.palette.secondary.main }} />
                    }}
                    values={{ username: user?.username }}
                />
                <Button fullWidth size="medium" variant="contained" color="secondary" href="/account" replace={true}>
                    {t('signupWelcome.card.button.account')}
                </Button>
            </CardContent>
        </AuthContainer>
    );
}
