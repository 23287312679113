import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileQuestion, retrieveUserProfileQuestions } from '../entities/profileQuestion';
import { retrieveProfileQuestionResponses } from '../entities/profileQuestionResponse';
import { RootState } from '../entities/store';
import { MediaAlbum } from './components/media';
import {
    EditProfileQuestion,
    EditProfileQuestionAutocompleteTags,
    EditProfileQuestionProps
} from './components/editProfileQuestion';
import { motion } from 'framer-motion';

function EditQuestionWidget(props: EditProfileQuestionProps) {
    const { widgetType } = props.profileQuestion;
    if (['text', 'textarea'].includes(widgetType)) return <EditProfileQuestion {...props} />;
    if (widgetType === 'autocomplete:tags') return <EditProfileQuestionAutocompleteTags {...props} />;
    return null;
}

export function EditInfoScreen() {
    const album = useSelector((state: RootState) => state.album);
    const profile = useSelector((state: RootState) => state.profile);
    const profileQuestion = useSelector((state: RootState) => state.profileQuestion);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(retrieveUserProfileQuestions());
    }, []);

    const userProfile = profile.userProfile;
    useEffect(() => {
        if (userProfile?.id) {
            dispatch(retrieveProfileQuestionResponses(userProfile.id));
        }
    }, [userProfile?.id]);

    if (userProfile) {
        const userAlbum = album.byId[userProfile.albums[0].id];
        return (
            <Box
                component={motion.div}
                animate="zoom"
                initial="initial"
                variants={{ zoom: { transition: { staggerChildren: 0.1 } } }}
                sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2 }}
            >
                <MediaAlbum album={userAlbum} />
                <motion.div
                    variants={{
                        initial: {
                            scale: 0
                        },
                        zoom: {
                            scale: 1
                        }
                    }}
                >
                    {profileQuestion.questions.map(
                        (profileQuestion: ProfileQuestion): React.ReactNode => (
                            <EditQuestionWidget
                                key={profileQuestion.id}
                                profile={userProfile}
                                profileQuestion={profileQuestion}
                            />
                        )
                    )}
                </motion.div>
            </Box>
        );
    }
    return <CircularProgress />;
}
