import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Area } from 'react-easy-crop/types';
import http, { ResponseError } from '../utils/http';
import { retrieveUserProfile } from './profile';
import { logoutUser } from './user';

export interface Media {
    author: string;
    id: number;
    type: 'image' | 'video';
    url: string;
}

export interface Album {
    id: number;
    media: Media[];
    text: string;
}

export type AlbumRaw = Album;

interface AlbumCreateImageData {
    album: Album;
    croppedArea: Area;
    file: File;
}

interface AlbumUpdateImageOrderData {
    album: Album;
    media: Media[];
}

interface AlbumUpdateImageOrderDataRaw {
    media: number[];
}

export interface AlbumState {
    error?: ResponseError;
    byId: { [id: number]: Album };
    requestStatus: 'init' | 'pending' | 'complete' | 'error';
}

const initialState: AlbumState = {
    byId: {},
    requestStatus: 'init'
};

export const createAlbumImage = createAsyncThunk<void, AlbumCreateImageData, { rejectValue: ResponseError }>(
    'album/create/image',
    async (data, thunkApi) => {
        try {
            const formData = new FormData();
            formData.append('image', data.file);
            formData.append('left', String(data.croppedArea.x));
            formData.append('upper', String(data.croppedArea.y));
            formData.append('right', String(data.croppedArea.x + data.croppedArea.width));
            formData.append('lower', String(data.croppedArea.y + data.croppedArea.height));
            const response = await http.createFile<void>('/content/album/:album/image/create/', formData, {
                urlParams: { album: data.album.id }
            });
            // Todo: replace with retrieveAlbumAction
            thunkApi.dispatch(retrieveUserProfile());
            return response;
        } catch (error) {
            return thunkApi.rejectWithValue(error as ResponseError);
        }
    }
);

export const updateAlbumImageOrder = createAsyncThunk<void, AlbumUpdateImageOrderData, { rejectValue: ResponseError }>(
    'album/image/update/order',
    async (data, thunkApi) => {
        try {
            const album = {
                ...data.album,
                media: data.media
            };
            thunkApi.dispatch(setAlbumFromRaw(album));
            return await http.update<void, AlbumUpdateImageOrderDataRaw>('/content/album/:album/update/', {
                data: { media: data.media.map((media: Media): number => media.id) },
                partial: true,
                urlParams: { album: data.album.id }
            });
        } catch (error) {
            return thunkApi.rejectWithValue(error as ResponseError);
        }
    }
);

export const deleteImage = createAsyncThunk<void, number, { rejectValue: ResponseError }>(
    'album/delete/image',
    async (image, thunkApi) => {
        try {
            await http.delete('/content/media/image/:image/delete/', {
                urlParams: { image }
            });
            // Todo: replace with retrieveAlbumAction
            thunkApi.dispatch(retrieveUserProfile());
        } catch (error) {
            return thunkApi.rejectWithValue(error as ResponseError);
        }
    }
);

export const albumSlice = createSlice({
    name: 'album',
    initialState,
    reducers: {
        setAlbumFromRaw: (state, action: PayloadAction<AlbumRaw>) => {
            state['byId'][action.payload.id] = action.payload;
        },
        setAlbumsFromRaw: (state, action: PayloadAction<AlbumRaw[]>) => {
            state.byId = {
                ...action.payload.reduce(
                    (state: AlbumState['byId'], album: AlbumRaw): AlbumState['byId'] => {
                        state[album.id] = album;
                        return state;
                    },
                    { ...state.byId }
                )
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(logoutUser.fulfilled, (state) => {
                Object.assign(state, initialState);
            })
            .addCase(createAlbumImage.fulfilled, (state) => {
                state.requestStatus = 'complete';
            })
            .addCase(createAlbumImage.pending, (state) => {
                state.requestStatus = 'pending';
            })
            .addCase(createAlbumImage.rejected, (state, action) => {
                state.error = action.payload;
                state.requestStatus = 'error';
            })
            .addCase(updateAlbumImageOrder.fulfilled, (state) => {
                state.requestStatus = 'complete';
            })
            .addCase(updateAlbumImageOrder.pending, (state) => {
                state.requestStatus = 'pending';
            })
            .addCase(updateAlbumImageOrder.rejected, (state, action) => {
                state.error = action.payload;
                state.requestStatus = 'error';
            })
            .addCase(deleteImage.fulfilled, (state) => {
                state.requestStatus = 'complete';
            })
            .addCase(deleteImage.pending, (state) => {
                state.requestStatus = 'pending';
            })
            .addCase(deleteImage.rejected, (state, action) => {
                state.error = action.payload;
                state.requestStatus = 'error';
            });
    }
});

export const setAlbumFromRaw = albumSlice.actions.setAlbumFromRaw;
export const setAlbumsFromRaw = albumSlice.actions.setAlbumsFromRaw;
export const albumReducer = albumSlice.reducer;
